import React from 'react';
import HeaderPR from '../PRTemplate/HeaderPR';
import TableGRN from '../PRTemplate/TableGRN';
import TablePR from '../PRTemplate/TablePR';
import TableDOItem from '../PRTemplate/TableDOItem';
import Table2PR from '../PRTemplate/Table2PR';
import FooterGRN from '../FooterGRN';
import FooterPR from '../FooterPR';
import FooterPO from '../FooterPO';

export const GPBodyTemplate = (props: any) => {
  const {
    accountType,
    getCompany,
    fontStyle,
    descriptionSize,
    title,
    docType,
    previewDetail,
    getUsersByAccountAndSoftware,
    remapData,
    page,
    pageLength,
  } = props;

  return (
    <>
      {previewDetail && remapData && accountType === '/' && (
        <>
          {page === 0 && (
            <>
              <HeaderPR />

              <div className="row">
                <p style={fontStyle}>
                  <b>{title}</b>
                </p>
              </div>
            </>
          )}

          <div style={{ border: '0.5px solid', margin: '10px' }}>
            {docType === 'good-return-note' || docType === 'delivery-order' ? (
              <TableGRN
                name={previewDetail?.DeliveryLocation?.Name}
                data={previewDetail}
                docType={docType}
                page={page}
                pageLength={pageLength}
              />
            ) : (
              <TablePR
                name={previewDetail?.DeliveryLocation?.Name}
                data={previewDetail}
                docType={docType}
                page={page}
                pageLength={pageLength}
              />
            )}

            {/* -------------TABLE LISTING-------------- */}
            {docType === 'delivery-order' || docType === 'good-return-note' ? (
              <TableDOItem
                data={remapData[page]}
                docType={docType}
                allData={previewDetail}
                page={page}
                pageLength={pageLength}
              />
            ) : (
              <Table2PR
                data={remapData[page]}
                docType={docType}
                allData={previewDetail}
                page={page}
                pageLength={pageLength}
              />
            )}
          </div>
          {/* ------------END------------ */}

          {page !== pageLength - 1 ||
          docType === 'good-return-note' ||
          docType === 'delivery-order' ? null : (
            <div style={{ fontSize: descriptionSize, margin: '10px' }}>
              {docType === 'purchase-requisition'
                ? 'Justification for Requisition:'
                : 'Special Instruction:'}{' '}
              <br />
              {previewDetail?.Instruction ?? 'NA'}
            </div>
          )}

          {docType !== 'purchase-requisition' &&
            docType !== 'delivery-order' &&
            docType !== 'good-return-note' &&
            page === pageLength - 1 && (
              <div style={{ fontSize: descriptionSize, margin: '10px' }}>
                Note : <br />
                1. If goods are not delivered by the date indicated above, the
                purchaser has the right to cancel the order. <br />
                2. Please notify us immediately if unable to supply. <br />
                3. Delivery Order and Invoice must accompany all shipments.{' '}
                <br />
                4. The PO number must appear on Invoice and Delivery Order.
                <br />
                5. We assume no responsibility for goods delivered without a
                Purchase Order. <br />
                6. Credit terms commence from date of invoice or receipt or
                goods, whichever is later.
              </div>
            )}

          {page === pageLength - 1 &&
          ['good-return-note', 'delivery-order'].includes(docType) ? (
            <FooterGRN
              data={previewDetail}
              docType={docType}
              getUser={getUsersByAccountAndSoftware}
            />
          ) : page === pageLength - 1 &&
            ['direct-po', 'centralised-po', 'generate-po-from-pr'].includes(
              docType,
            ) ? (
            <FooterPO
              data={previewDetail}
              docType={docType}
              getUser={getUsersByAccountAndSoftware}
            />
          ) : page === pageLength - 1 ? (
            <FooterPR
              data={previewDetail}
              getUser={getUsersByAccountAndSoftware}
            />
          ) : (
            <div className="page-break"></div>
          )}
        </>
      )}
    </>
  );
};

export default GPBodyTemplate;
