import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const APCreditorAgingSummaryByDocDateReport = () => {
  const history = useHistory()
  //const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: editData?.CompanyID,
    },
    {
      name: 'docDate',
      value: new Date(editData?.DocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'creditortypeid',
      value: editData?.CreditorTypeID,
    },
    {
      name: 'creditoraccountid',
      value: editData?.CreditorAccountID,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/account-payable`)}
        mainBtn="close"
        smTitle="Accounts Payable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Creditor Aging Summary By Document Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('CreditorAgingSummaryByDocDate', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
