import React from 'react'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import { DatePicker } from '@material-ui/pickers'
import { changeDateFormat } from '../../../helpers/Functions/dateFunction'
//*********************************************************//
//----------------COMPONENT FILTER DATE RANGE--------------//
//*********************************************************//
export const SearchFilterV3 = ({
  openFilterDateDialog,
  setOpenFilterDateDialog,
  handleDateConfirm,
  handleDateReset,
  currentStartDate,
  currentEndDate,
  setCurrentStartDate,
  setCurrentEndDate,
  confirmedStartDate,
  confirmedEndDate,
  setConfirmedStartDate,
  setConfirmedEndDate,
}) => {
  const onClickReset = () => {
    setCurrentStartDate(null)
    setCurrentEndDate(null)
    setConfirmedStartDate(null)
    setConfirmedEndDate(null)
    handleDateReset()
  }

  const handleConfirmSort = () => {
    setConfirmedStartDate(currentStartDate)
    setConfirmedEndDate(currentEndDate)
    handleDateConfirm({
      currentStartDate,
      currentEndDate,
    })
    setOpenFilterDateDialog(false)
  }

  const handleCloseDialog = () => {
    setCurrentStartDate(confirmedStartDate)
    setCurrentEndDate(confirmedEndDate)
    setOpenFilterDateDialog(false)
  }

  return (
    <StandardDialog
      fullWidth
      open={openFilterDateDialog}
      onClose={handleCloseDialog}
      sections={{
        header: {
          title: 'Filter',
          actions: [
            {
              displayText: 'Reset',
              props: {
                onClick: onClickReset,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
        body: () => (
          <CardContents>
            <>
              <div className="date-filter mdDesc">Doc Date</div>
              <DatePicker
                autoOk
                variant="inline"
                disableToolbar
                showTodayButton
                label="Start Date"
                fullWidth
                className="date-filter left"
                margin="normal"
                format="dd MMM yyyy"
                value={currentStartDate}
                maxDate={currentEndDate}
                onChange={date =>
                  setCurrentStartDate(changeDateFormat(date, 'YYYY-MM-DD'))
                }
              />
              <DatePicker
                autoOk
                variant="inline"
                disableToolbar
                showTodayButton
                label="End Date"
                fullWidth
                className="date-filter right"
                margin="normal"
                format="dd MMM yyyy"
                value={currentEndDate}
                minDate={currentStartDate}
                onChange={date =>
                  setCurrentEndDate(changeDateFormat(date, 'YYYY-MM-DD'))
                }
              />
            </>
          </CardContents>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: handleCloseDialog,
                variant: 'contained',
                color: 'secondary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: handleConfirmSort,
                variant: 'contained',
                color: 'primary',
                disabled: !currentStartDate || !currentEndDate,
              },
            },
          ],
        },
      }}
    />
  )
}
