import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { maxHeight } from '@material-ui/system'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const StockBalanceByDocDateReport = () => {
  const history = useHistory()
  const { WarehouseID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: editData?.CompanyID,
    },
    {
      name: 'docdate',
      value: new Date(editData?.DocDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'warehouseid',
      value: editData?.WarehouseID,
    },
    {
      name: 'stockcategoryid',
      value: editData?.StockCategoryID,
    },
    {
      name: 'stockitemid',
      value: editData?.StockItemID,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/inventory-control`)}
        mainBtn="close"
        smTitle="Inventory Control"
        title={user?.accountName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reporting' },
          { name: 'Stock Balance By Document Date', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('StockBalanceReportByLocation', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
