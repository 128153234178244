import theme from '@ifca-root/react-component/src/assets/theme'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import {
  StyledMenu,
  StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import AppContext from 'containers/App/Store/AppContext'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { dropdownOption } from 'containers/DigitalReportModule/function/DigitalReportDropdown'
import { useLazyQuery } from '@apollo/react-hooks'
import { GET_PACKAGE_BY_ACCOUNT } from 'containers/AccountModule/GraphQL/AccountQuery'
import { SubscriptionClient } from 'SubscriptionClient'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'

export const ARDigitalReportSubmenu = (props: any) => {
  useEffect(() => {
    console.log('ARDigitalReportSubmenu')
  }, [])
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const location = useLocation()
  const isHomeDefault = localStorage.getItem('isDefault')
  const CompanyID = localStorage.getItem('latestCompany')
  const { handlePermHidden } = usePermissionChecker()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [labelState, setLabelState] = useState<string>(
    'Account Receivable Reports'
  )
  const { mode } = props

  // ACCOUNTX API CALLS
  const [
    loadPackage,
    {
      loading: packageLoading,
      error: packageError,
      data: { getPackageByAccount } = { getPackageByAccount: [] },
    },
  ] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
    client: SubscriptionClient,
  })

  const submenuList = [
    {
      path: `/digital-reports/account-receivable/ar-debtor-ledger-detail-by-doc-date/parameters`,
      title: `Debtor Ledger Detail By Doc Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-statement-of-account/parameters`,
      title: `Statement Of Account`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-summary-trx-date/parameters`,
      title: `Debtor Aging Summary by Trx Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-summary-doc-date/parameters`,
      title: `Debtor Aging Summary by Document Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-aging-detail-trx-date/parameters`,
      title: `Debtor Aging Detail by Trx Date`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-control-summary/parameters`,
      title: `Debtor Control Summary`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
    {
      path: `/digital-reports/account-receivable/ar-debtor-control-detail/parameters`,
      title: `Debtor Control Detail`,
      //   permission: AcctPermission.GeneralLedgerDigitalReportsGlJournalView,
    },
  ]

  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const backPath = () => {
    if (isHomeDefault === 'true') return history.push(`/`)
    else {
      history.push({
        pathname:
          mode === 'general-ledger'
            ? `/general-ledger/${CompanyID}`
            : mode === 'cash-book'
            ? `/cash-book/${CompanyID}`
            : mode === 'account-receivable'
            ? `/account-receivable/${CompanyID}`
            : mode === 'general-purchase'
            ? `/general-purchase/${CompanyID}`
            : mode === 'inventory-control'
            ? `/inventory-control/${CompanyID}`
            : mode === 'fixed-assets'
            ? `/fixed-assets/${CompanyID}`
            : `/account-payable/${CompanyID}`,
      })
    }
  }

  const handleChange = value => {
    history.push({
      pathname: value,
    })
  }

  let package_permission
  if (!packageLoading) {
    package_permission = dropdownOption.filter((v, i) =>
      getPackageByAccount?.includes(v?.package_name)
    )
  }

  useEffect(() => {
    if (user?.accountID) {
      loadPackage({
        variables: {
          softwareCode: 'ACCOUNT',
          accountID: user?.accountID,
        },
      })
    }
  }, [user?.accountID])

  return (
    <>
      {packageLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={backPath}
        smTitle={'Accounts Receivable'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Digital Reports', current: true },
        ]}
      />
      <DynamicSubHeader
        title={labelState}
        handleClick={e => {
          handleClick(e)
        }} // onClick function for dropdown
        dropDownOptions={
          // dropdown component place here
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {package_permission?.map(x => {
              if (
                handlePermHidden({
                  permEnum: x?.permission,
                })
              )
                return (
                  <StyledMenuItem
                    onClick={e => {
                      // setLabelState(x?.label)
                      handleChange(x?.path)
                      handleClose()
                    }}
                    selected={location.pathname.includes(x.path)}
                  >
                    <ListItemText primary={x.label} />
                  </StyledMenuItem>
                )
            })}
          </StyledMenu>
        }
      />
      <ContentWrapper
        float
        style={{
          marginTop: isDesktop ? '100px' : '40px',
          top: isDesktop ? '47px' : null,
        }}
      >
        <List className="core-list">
          {submenuList.map((el, index) => {
            // if (
            //   handlePermHidden({
            //     // companyID: CompanyID,
            //     permEnum: el?.permission,
            //   })
            // )
            return (
              <ListItem key={index} onClick={() => history.push(el?.path)}>
                <ListItemText
                  style={{
                    textAlign: 'right',
                    marginLeft: '5px',
                  }}
                  primary={<span className="xsTitle">{el?.title}</span>}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el.path,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </ContentWrapper>
      {/* FOOTER */}
    </>
  )
}
