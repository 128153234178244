import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { AddBox } from '@material-ui/icons'
import AttachMoney from '@material-ui/icons/AttachMoney'
import DeleteIcon from '@material-ui/icons/Delete'
import InfoIcon from '@material-ui/icons/Info'
import BlueDollar from 'assets/icons/Money/blue-dollar.svg'
import {
  RecordStatus,
  useGetBudgetQuery,
  useGetCompanyQuery,
} from 'generated/graphql'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useParams } from 'react-router'
import { uuid } from 'uuidv4'
import './BudgetSetup.scss'

interface BudgetSetupProps {
  AccountID: string
  RecordStatus: string
  BudgetID: string
  CompanyID: string
  Fyear: number
  Version: string
  Description: string
  IsDefault: boolean
  BudgetItem: any
  BudgetCostCentre: any
}

export const BudgetSetupCostCentreForm = (props: any) => {
  let history = useHistory()
  const {
    CompanyID,
    BudgetItemID,
    MasterCOAID,
    BudgetItemCostCentreID,
  }: any = useParams()
  const { mode } = props
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const budgetItemCC = JSON.parse(localStorage.getItem('budgetCC'))
  const currBudgetItemCC = budgetItemCC?.find(
    v => v.BudgetItemID === BudgetItemID
  )

  const budgetItemPeriod = JSON.parse(localStorage.getItem('budgetPeriod'))

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompantError,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID, RecordStatus: RecordStatus.Active },
  })

  const {
    loading: BudgetLoading,
    error: BudgetError,
    data: { getBudget: getBudget } = { getBudget: [] },
  } = useGetBudgetQuery({
    fetchPolicy: 'network-only',
    variables: {},
  })

  const { handleSubmit, register, setValue, control } = useForm<
    BudgetSetupProps
  >({
    defaultValues: {
      BudgetCostCentre: !!budgetItemPeriod
        ? budgetItemPeriod.map(v => {
            return {
              ID: v?.BudgetItemCostCentreID,
              CostCentreID: v?.CostCentreID,
              Code: v?.CostCentreCode,
              BudgetAmt: v?.BudgetAmt,
            }
          })
        : [],
    },
    mode: 'all',
  })

  const { fields, append, remove, insert, prepend } = useFieldArray({
    control,
    name: 'BudgetCostCentre',
  })

  const onSubmit = (data, mode?) => {
    switch (mode) {
      case 'budgetPeriod':
        localStorage.setItem(
          'budgetPeriod',
          JSON.stringify(
            data?.BudgetCostCentre?.map((x, index) => {
              return {
                BudgetItemCostCentreID: x?.BudgetItemCostCentreID,
                CostCentreID: x?.CostCentreID,
                Code: curCompany[0]?.CostCentre.filter(
                  cc => cc?.CostCentreID === x?.CostCentreID
                )[0]?.Name,
                BudgetAmt: x?.BudgetAmt,
              }
            })
          )
        )

        break
    }
  }

  return (
    <>
      {BudgetLoading && <Loading />}
      {CompanyLoading && <Loading />}

      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push(
            `/general-ledger/${CompanyID}/company-setting/budget/add`
          )
          localStorage.removeItem('budgetPeriod')
        }}
        smTitle={'Company Setting'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Company' },
          { name: 'Budget' },
          { name: 'Department Budget', current: true },
        ]}
      />

      <DynamicSubHeader
        title={
          <span style={{ color: 'darkblue' }}>{currBudgetItemCC?.Code}</span>
        }
        //title={<>{getBudget[0]?.Fyear}</>}
        infoLine={<span className="xxTitle">{getBudget[0]?.Fyear}</span>}
        rightText={
          <span style={{ paddingRight: '0px', color: 'blue' }}>
            <img
              src={BlueDollar}
              style={{
                width: '12px',
                marginBottom: '-2px',
                font: 'xxTitle highlight-text',
              }}
            />
            {amtStr(currBudgetItemCC?.BudgetAmt)}
          </span>
        }
      />

      <ContentWrapper multiDynamicInfo float>
        <div className="message-alert text-noflow">
          <div>
            <span className="icon info-icon">
              {<InfoIcon style={{ color: 'orange' }} />}
            </span>
            <span className="desc">Click{'  '}</span>
            <span className="icon info-icon">
              {<AttachMoney style={{ color: 'blue' }} />}
            </span>
            <span className="desc">
              {''}to add{' '}
              <span className="c-darkblack xxTitle">Period Budget</span>
            </span>
          </div>
        </div>
        <CardContents
          section={{
            header: {
              title: 'Department',

              onClickAction: () =>
                append({
                  ID: uuid(),
                  CostCentreID: '',
                  Name: '',
                  BudgetAmt: '',
                }),

              icon: <AddBox style={{ color: '#2f53e6' }} />,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {fields?.map((v, index) => {
              return (
                <>
                  <Grid
                    key={v.id}
                    container
                    spacing={1}
                    justify="flex-start"
                    className="table-content"
                  >
                    <Grid
                      item
                      xs={1}
                      style={{ alignSelf: 'center', color: 'white' }}
                    >
                      <span className="circle">{index + 1}</span>
                    </Grid>

                    <Grid container item xs={8}>
                      <Grid
                        item
                        xs={6}
                        className="mat-new"
                        style={{ paddingRight: '10px' }}
                      >
                        <div hidden={true}>
                          <Controller
                            render={({ onChange, onBlur, value }) => (
                              <TextField key={v?.id} hidden={true}></TextField>
                            )}
                            name={`BudgetCostCentre[${index}].BudgetItemCostCentreID`}
                            control={control}
                            ref={register()}
                            defaultValue={v.ID}
                          />
                        </div>

                        <Controller
                          render={({ onChange, onBlur, value }) => (
                            <TextField
                              key={v?.id}
                              select
                              defaultValue={v.CostCentreID}
                              required
                              variant="outlined"
                              onChange={e => {
                                register(
                                  `BudgetCostCentre[${index}].CostCentreID`
                                )
                                setValue(
                                  `BudgetCostCentre[${index}].CostCentreID`,
                                  e?.target?.value
                                )
                                register(
                                  `BudgetCostCentre[${index}].CostCentreCode`
                                )
                                setValue(
                                  `BudgetCostCentre[${index}].CostCentreCode`,
                                  curCompany[0]?.CostCentre.filter(
                                    cc => cc?.CostCentreID === e?.target?.value
                                  )[0]?.Name
                                )
                              }}
                            >
                              {curCompany[0]?.CostCentre.map((el, index) => (
                                <MenuItem value={el.CostCentreID} key={index}>
                                  {el.Name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          name={`BudgetCostCentre[${index}].CostCentreID`}
                          autoComplete="off"
                          control={control}
                          select
                          ref={register()}
                          defaultValue={v.CostCentreID}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="mat-new"
                        style={{ paddingLeft: '10px' }}
                      >
                        <Controller
                          render={({ onChange }) => (
                            <NumberFormat
                              key={v?.id}
                              allowNegative={true}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale
                              variant="outlined"
                              autoComplete="off"
                              thousandSeparator
                              isNumericString
                              margin="dense"
                              onValueChange={values => {
                                let { floatValue } = values
                                onChange(
                                  values.floatValue,
                                  register(
                                    `BudgetCostCentre[${index}].BudgetAmt`
                                  ),
                                  setValue(
                                    `BudgetCostCentre[${index}].BudgetAmt`,
                                    floatValue
                                  )
                                )
                              }}
                              defaultValue={v.BudgetAmt || 0}
                            />
                          )}
                          required
                          ref={register()}
                          name={`BudgetCostCentre[${index}].BudgetAmt`}
                          control={control}
                          onChangeName="onValueChange"
                          onChange={([{ value, floatValue }]) => {
                            return value
                          }}
                          disabled={mode === 'approve-reject'}
                          defaultValue={v.BudgetAmt || 0}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={1} className="delete-icon ">
                      <IconButton
                        onClick={() => {
                          handleSubmit(data => onSubmit(data, 'budgetPeriod'))()
                          history.push(
                            `/general-ledger/${CompanyID}/company-setting/budget/add/${BudgetItemID}/budgetcostcentre/${v.ID}/budgetPeriod`
                          )
                        }}
                      >
                        <AttachMoney style={{ color: 'blue' }} />
                      </IconButton>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      style={{ placeSelf: 'center', textAlign: 'center' }}
                    >
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon color={'error'} />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider
                    variant="fullWidth"
                    style={{ background: '#7A7A7A' }}
                  ></Divider>
                </>
              )
            })}
          </div>
        </CardContents>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {},
            props: { type: 'submit' },
          },
        ]}
      />
    </>
  )
}
