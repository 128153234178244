import React from 'react'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import './Sort.scss'

export const SortDialog = ({
  openSortDialog,
  setOpenSortDialog,
  title,
  fieldList,
  onSortConfirm,
  selectedField,
  setSelectedField,
  sortOrder,
  setSortOrder,
}) => {
  const handleChangeSort = event => {
    setSortOrder(event.target.value)
  }

  const handleChangeSelect = event => {
    setSelectedField(event.target.value)
  }

  const onClickReset = () => {
    setSelectedField('')
    setSortOrder(null)
  }

  const handleConfirmSort = () => {
    onSortConfirm({ selectedField, sortOrder })
    setOpenSortDialog(false)
  }

  return (
    <StandardDialog
      fullWidth
      open={openSortDialog}
      onClose={() => setOpenSortDialog(false)}
      sections={{
        header: {
          title: title,
          actions: [
            {
              displayText: 'Reset',
              props: {
                onClick: onClickReset,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
        body: () => (
          <CardContents>
            <TextField
              fullWidth
              id="standard-select-field"
              select
              label="Sort By"
              margin="normal"
              value={selectedField}
              onChange={handleChangeSelect}
            >
              {fieldList?.map(v => (
                <MenuItem value={v?.value} key={v.Name}>
                  {v?.Name}
                </MenuItem>
              ))}
            </TextField>

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend" style={{ fontSize: '12px' }}>
                Order By
              </FormLabel>
              <RadioGroup
                aria-label="Order"
                name="Order"
                value={sortOrder}
                onChange={handleChangeSort}
                row
                className="radio-size"
              >
                <FormControlLabel
                  value="ASC"
                  control={<Radio color="primary" />}
                  label="Ascending"
                />
                <FormControlLabel
                  value="DESC"
                  control={<Radio color="primary" />}
                  label="Descending"
                />
              </RadioGroup>
            </FormControl>
          </CardContents>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenSortDialog(false),
                variant: 'contained',
                color: 'secondary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: handleConfirmSort,
                variant: 'contained',
                color: 'primary',
                disabled: !selectedField || !sortOrder,
              },
            },
          ],
        },
      }}
    />
  )
}
