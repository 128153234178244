import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation } from 'react-router'

export const CashLedgerListingReport = () => {
  const history = useHistory()
  //const { CompanyID, BankAccountID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: editData?.CompanyID,
    },
    {
      name: 'bankaccountid',
      value: editData?.BankAccountID,
    },
    {
      name: 'startdate',
      value: new Date(editData?.StartDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'enddate',
      value: new Date(editData?.EndDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'docno',
      value: editData?.DocNo,
    },
    {
      name: 'refno',
      value: editData?.Refno,
    },
    {
      name: 'paytorecv',
      value: editData?.Paytorecv,
    },
    {
      name: 'descrip',
      value: editData?.Description,
    },
    {
      name: 'ledgertype',
      value: editData?.JournalType,
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  console.log(
    generatedurl('CashLedgerListingReport', parameters),
    'urlGenerate'
  )

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/cash-book`)}
        mainBtn="close"
        smTitle="General Ledger"
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Cash Ledger Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('CashLedgerListingReport', parameters),
          host: accountxReportUrl,
          // host: contractReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
