import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import React from 'react'
import { useHistory } from 'react-router'

export const ErrorDialog = (props: any) => {
  const {
    errorDia,
    setErrorDia,
    errorMsg,
    errorHeaderMsg,
    isApprovalPolicy = false,
    onclick,
    isProceed,
  } = props

  const history = useHistory()
  const isAuth = () => {
    const lowerCaseErr = errorMsg?.toLowerCase()
    if (
      lowerCaseErr?.includes('not authenticated') ||
      lowerCaseErr?.includes('jwt') ||
      lowerCaseErr?.includes('no company was assigned to you')
    )
      return false
    else return true
  }

  return (
    <CommonDialog
      fullWidth={true}
      open={errorDia}
      onClose={() => setErrorDia(false)}
      sections={{
        header: {
          title: !!errorHeaderMsg
            ? `${errorHeaderMsg}
              ${errorHeaderMsg?.includes('Error!') ? '' : '!'}`
            : 'Error!',
          style: { color: 'red' },
        },
        body: () => (
          <div className="content-wrap full text-noflow">
            <div className="mdDesc text-noflow">
              {isAuth()
                ? errorMsg
                : errorMsg
                    ?.toLowerCase()
                    ?.includes('no company was assigned to you')
                ? errorMsg
                : 'Session has Expired/Not Authenticated!'}
            </div>
          </div>
        ),
        footer: {
          actions: isProceed
            ? [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Proceed',
                  props: {
                    onClick: () =>
                      isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ]
            : [
                {
                  displayText: 'Close',
                  props: {
                    onClick: isAuth()
                      ? isApprovalPolicy
                        ? () => onclick()
                        : () => setErrorDia(false)
                      : () => {
                          setErrorDia(false)
                          localStorage?.removeItem('loggedInUser')
                          localStorage?.removeItem('permission')
                          history.push('/login')
                        },
                    // THE PREVIOUS CODE FOR REFERENCE
                    // onClick: () =>
                    //   isApprovalPolicy ? onclick() : setErrorDia(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
        },
      }}
    />
  )
}
