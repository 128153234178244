import { StockBalanceByDocDateReport } from './GeneratedReport/StockBalanceByDocDateReport'
import { StockBalanceByTrxDateReport } from './GeneratedReport/StockBalanceByTrxDateReport'
import { StockLedgerByLocationReport } from './GeneratedReport/StockLedgerByLocationReport'
import { ICDigitalReportSubmenu } from './ICDigitalReportSubmenu'
import { StockBalanceByDocDateParamsForm } from './Parameters/StockBalanceByDocDateParamsForm'
import { StockBalanceByTrxDateParamsForm } from './Parameters/StockBalanceByTrxDateParamsForm'
import { StockLedgerByLocationParamsForm } from './Parameters/StockLedgerByLocationParamsForm'
import React from 'react'
import { StockUsageByDepartmentParamsForm } from './Parameters/StockUsageByDepartmentParamsForm'
import { StockUsageByDepartmentReport } from './GeneratedReport/StockUsageByDepartmentReport'
import { StockTransferReportParamsForm } from './Parameters/StockTransferReportParamsForm'
import { StockTransferReport } from './GeneratedReport/StockTransferReport'

const icDigitalReportRoute = [
  {
    props: {
      exact: true,
      path: '/digital-reports/inventory-control',
    },
    component: <ICDigitalReportSubmenu mode="inventory-control" />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-location-report/parameters',
    },
    component: <StockLedgerByLocationParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-ledger-by-location-report/generated',
    },
    component: <StockLedgerByLocationReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-document-date/parameters',
    },
    component: <StockBalanceByDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-document-date/generated',
    },
    component: <StockBalanceByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-transaction-date/parameters',
    },
    component: <StockBalanceByTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-balance-by-transaction-date/generated',
    },
    component: <StockBalanceByTrxDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-usage-by-department/parameters',
    },
    component: <StockUsageByDepartmentParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-usage-by-department/generated',
    },
    component: <StockUsageByDepartmentReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-transfer-report/parameters',
    },
    component: <StockTransferReportParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/inventory-control/stock-transfer-report/generated',
    },
    component: <StockTransferReport />,
  },
]

export default icDigitalReportRoute
