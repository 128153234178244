import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useLatestOpenPeriodCheckingDateQuery } from 'generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import * as yup from 'yup'
import { ErrorDialog } from './ErrorDialog'

export const DateDialog = (props: any) => {
  const { onSubmit, openDialog, setOpenDialog, CompanyID } = props
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const FormSchema = yup.object().shape({
    DocDate: yup.string().required('Doc Date is Required'),
  })

  const { handleSubmit, register, control, watch } = useForm({
    resolver: yupResolver(FormSchema),
  })

  //to check whether period have closed or not
  const {
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  })

  return (
    <>
      <CommonDialog
        fullWidth={true}
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
        }}
        sections={{
          header: {
            title: `Generate to Payment`,
          },
          body: () => (
            <>
              <div
                className="content-container"
                style={{ marginTop: '-10px', fontSize: '16px' }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    required
                    value={new Date()}
                    as={KeyboardDatePicker}
                    name="DocDate"
                    label="Date"
                    control={control}
                    onChange={(date: Date | null) => {}}
                    format="dd/MM/yyyy"
                    margin="dense"
                    allowKeyboardControl
                    ref={register}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    defaultValue={new Date()}
                    showTodayButton
                    minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
                    maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setOpenDialog(false)
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },

              {
                displayText: 'Proceed',
                props: {
                  onClick: () => {
                    handleSubmit(data => onSubmit(data))()
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
