import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { format } from 'date-fns'
import React from 'react'

export const TableCBPV = (props: any) => {
  const { data, item, page, pageLength, currencyCode } = props

  ////////TO DETECT CHANGE IN SCREEN SIZE///////
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 9
  }

  ///// style border table
  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingTop: '4px',
    paddingBottom: '4px',
  }

  const thleftnarrow = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
  }

  const thleftmost = {
    width: '10%',
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
    backgroundColor: '#e7e6e6',
    paddingLeft: '8px',
  }

  const thcenter = {
    width: '10%',
    borderBottom: '0.5px solid',
    textAlign: 'right' as 'right',
    backgroundColor: '#e7e6e6',
    paddingRight: '1em',
  }

  const tdright = {
    width: '10%',
    borderTop: '0.5px  solid',
    textAlign: 'right' as 'right',
    paddingRight: '1em',
  }

  const tdleft = {
    borderTop: '0.5px  solid',
    textAlign: 'left' as 'left',
    paddingLeft: '8px',
  }

  const tdcenteramt = {
    textAlign: 'right' as 'right',
    paddingRight: '1em',
  }

  return (
    <>
      <div style={{ marginTop: '5px' }}>
        <table
          style={{
            width: '100%',
            fontSize,
            // borderCollapse: 'collapse',
            borderSpacing: '0 1em',
          }}
        >
          <tr>
            <th style={thleftmost}>Doc Date</th>
            <th style={thleftnarrow}>Acc No</th>
            <th style={thleftnarrow}>Doc No</th>
            <th style={thleft}>Remark</th>
            <th style={thcenter}>Tax Rate</th>
            <th style={thcenter}>Base Amt</th>
            <th style={thcenter}>Tax Amt</th>
            <th style={thcenter}>Total Amt</th>
          </tr>
          {item?.map((v, i) => (
            <>
              <tr>
                <td style={{ textAlign: 'left', paddingLeft: '8px' }}>
                  {v?.DocDate
                    ? format(
                        new Date(v?.DocDate ?? v?.DebitDocDate),
                        'dd/MM/yyyy'
                      )
                    : 'NA'}
                </td>
                <td style={{ textAlign: 'left' }}>
                  {v?.MasterCOA?.Code ?? 'NA'}
                </td>
                <td style={{ textAlign: 'left' }}>{v?.DocNo ?? 'NA'}</td>
                <td style={{ textAlign: 'left' }}>{v?.Remark ?? 'NA'}</td>
                <td style={tdcenteramt}>{`${v?.TaxRate}%`}</td>
                <td style={tdcenteramt}>{amtStr(v?.Amount)}</td>
                <td style={tdcenteramt}>{amtStr(v?.TaxAmt)}</td>
                <td style={tdcenteramt}>{amtStr(v?.DocAmt)}</td>
              </tr>
            </>
          ))}
        </table>
      </div>
      {page === pageLength - 1 && (
        <div>
          <table
            style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}
          >
            <tr>
              <td style={tdleft}>{`Total Amount`}</td>

              <td style={tdright}>{amtStr(data?.DocAmt)}</td>
            </tr>
          </table>
        </div>
      )}
    </>
  )
}

export default TableCBPV
