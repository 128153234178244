import React from 'react'
import { ARDebtorLedgerDetailbyDocDateParamsForm } from './Parameters/ARDebtorLedgerDetailByDocDateParamsForm'
import { ARDigitalReportSubmenu } from './ARDigitalReportSubmenu'
import { ARDebtorLedgerDetailByDocDateReport } from './GeneratedReport/ARDebtorLedgerDetailByDocDateReport'
import { ARStatementOfAccountParamsForm } from './Parameters/ARStatementOfAccountParamsForm'
import { ARStatementOfAccountReport } from './GeneratedReport/ARStatementOfAccountReport'
import { ARDebtorAgingSummarybyTrxDateParamsForm } from './Parameters/ARDebtorAgingSummaryByTrxDateParamsForm'
import { ARDebtorAgingSummaryByTrxDateReport } from './GeneratedReport/ARDebtorAgingSummaryByTrxDateReport'
import { ARDebtorAgingDetailByTrxDate } from './GeneratedReport/ARDebtorAgingDetailByTrxDate'
import { ARDebtorAgingDetailByTrxDateForms } from './Parameters/ARDebtorAgingDetailByTrxDateForms'
import { ARDebtorControlSummary } from './GeneratedReport/ARDebtorControlSummary'
import { ARDebtorControlSummaryForms } from './Parameters/ARDebtorControlSummaryForms'
import { ARDebtorControlDetailForms } from './Parameters/ARDebtorControlDetailForms'
import { ARDebtorControlDetail } from './GeneratedReport/ARDebtorControlDetail'
import { ARDebtorAgingSummarybyDocDateParamsForm } from './Parameters/ARDebtorAgingSummaryByDocDateParamsForm'
import { ARDebtorAgingSummaryByDocDateReport } from './GeneratedReport/ARDebtorAgingSummaryByDocDateReport'

const arDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/digital-reports/account-receivable',
    },
    component: <ARDigitalReportSubmenu mode="account-receivable" />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-ledger-detail-by-doc-date/parameters',
    },
    component: <ARDebtorLedgerDetailbyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-ledger-detail-by-doc-date/generated',
    },
    component: <ARDebtorLedgerDetailByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-statement-of-account/parameters',
    },
    component: <ARStatementOfAccountParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-statement-of-account/generated',
    },
    component: <ARStatementOfAccountReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-summary-trx-date/parameters',
    },
    component: <ARDebtorAgingSummarybyTrxDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-summary-trx-date/generated',
    },
    component: <ARDebtorAgingSummaryByTrxDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-summary-doc-date/parameters',
    },
    component: <ARDebtorAgingSummarybyDocDateParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-summary-doc-date/generated',
    },
    component: <ARDebtorAgingSummaryByDocDateReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-detail-trx-date/parameters',
    },
    component: <ARDebtorAgingDetailByTrxDateForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-aging-detail-trx-date/generated',
    },
    component: <ARDebtorAgingDetailByTrxDate />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-control-summary/parameters',
    },
    component: <ARDebtorControlSummaryForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-control-summary/generated',
    },
    component: <ARDebtorControlSummary />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-control-detail/parameters',
    },
    component: <ARDebtorControlDetailForms />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/account-receivable/ar-debtor-control-detail/generated',
    },
    component: <ARDebtorControlDetail />,
  },
]

export default arDigitalReportRoutes
