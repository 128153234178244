import {
  RecordStatus,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCostCentreCodeLazyQuery,
  useGetEntityCoaLazyQuery,
  useGetMasterCoaCodeQuery,
  useGetStockCategoryNameQuery,
  useGetStockItemNameQuery,
  useGetTransactionTypeQuery,
  useGetWarehouseNameLazyQuery,
} from '@account-root/account-react/src/generated/graphql'
import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { AccountFooter } from '@ifca-root/react-component/src/components/Footer/AccountFooter'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import React, { Fragment, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

interface StockUsageParamProps {
  CompanyID: string
  StartDate: Date
  EndDate: Date
  TrxnStartDate: Date
  TrxnEndDate: Date
  DocNo: string
  DocRef: string
  StockCategoryID: string
  StockItemID: string
  WarehouseID: string
  DocType: string
  TransactionTypeID: string
  MasterCOAID: string
  CostCentreID: string
  Amount: number
}

interface CompanyOption {
  Name: string
  CompanyID: string
}

export const StockUsageByDepartmentParamsForm = (props: any) => {
  let history = useHistory()
  let location = useLocation()
  //   const CompanyID = localStorage.getItem('latestCompany')
  const editData = location?.state as any
  const latestCompany = localStorage.getItem('latestCompany')
  const ParamsSchema = yup.object().shape({
    CompanyID: yup.string().required('Company is required'),
    StartDate: yup.string().required('Start Date is required'),
    EndDate: yup.string().required('End Date is required'),
  })
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const user = JSON.parse(localStorage.getItem('loggedInUser'))

  const {
    handleSubmit,
    register,
    errors,
    control,
    getValues,
    watch,
    setValue,
  } = useForm<StockUsageParamProps>({
    defaultValues: {},
    mode: 'all',
    resolver: yupResolver(ParamsSchema),
  })

  // Q U E R Y

  const {
    loading: CompanyLoading,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadWarehouse({
          variables: {
            orderByAsc: 'Name',
            CompanyID: latestCompany,
          },
        })
        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'Code',
            IsLastNode: true,
          },
        })
      }
    },
  })

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (latestCompany) {
        loadWarehouse({
          variables: {
            orderByAsc: 'Name',
            CompanyID: latestCompany,
          },
        })
        loadEntityCOA({
          variables: { CompanyID: latestCompany },
        })
        loadCostCentre({
          variables: {
            CompanyID: latestCompany,
            orderByAsc: 'Code',
            IsLastNode: true,
          },
        })
      }
    },
  })

  const [
    loadWarehouse,
    {
      loading: WarehouseLoading,
      data: { getWarehouse } = { getWarehouse: [] },
    },
  ] = useGetWarehouseNameLazyQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: StockCategoryNameLoading,
    data: { getStockCategory } = { getStockCategory: [] },
  } = useGetStockCategoryNameQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: StockItemLoading,
    data: { getStockItem } = { getStockItem: [] },
  } = useGetStockItemNameQuery({
    fetchPolicy: 'network-only',
  })

  const {
    loading: TransactionTypeLoading,
    data: { getTransactionType } = { getTransactionType: [] },
  } = useGetTransactionTypeQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
      RecordStatus: RecordStatus.Active,
      IsSystem: false,
    },
  })

  const [
    loadEntityCOA,
    {
      loading: EntityCOALoading,
      data: { getEntityCOA } = { getEntityCOA: [] },
    },
  ] = useGetEntityCoaLazyQuery({
    fetchPolicy: 'network-only',
    // variables: { CompanyID: CompanyID },
  })

  const {
    loading: MasterCOALoading,
    data: { getMasterCOA } = { getMasterCOA: [] },
  } = useGetMasterCoaCodeQuery({
    fetchPolicy: 'network-only',
    variables: { IsLastNode: true, orderByAsc: 'Name' },
  })

  let masterCOAList =
    getEntityCOA?.length > 0
      ? getEntityCOA
          ?.map(coa => coa?.MasterCOA)
          ?.filter(coa => coa?.IsLastNode === true && coa?.IsControl === false)
      : getMasterCOA?.filter(
          coa => coa?.IsLastNode === true && coa?.IsControl === false
        )

  const [
    loadCostCentre,
    {
      loading: CostCentreLoading,
      data: { getCostCentre } = { getCostCentre: [] },
    },
  ] = useGetCostCentreCodeLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [selectedStockCategory, setSelectedStockCategory] = useState(new Set())
  const handleCheckboxStockCategoryChange = (event: any) => {
    let category = selectedStockCategory

    if (event?.target?.checked) {
      category.add(event?.target?.value)
    } else {
      category.delete(event?.target?.value)
    }
    setSelectedStockCategory(new Set(category))
  }

  const [selectedStockItem, setSelectedStockItem] = useState(new Set())
  const handleCheckboxStockItemChange = (event: any) => {
    let stockItem = selectedStockItem

    if (event?.target?.checked) {
      stockItem.add(event?.target?.value)
    } else {
      stockItem.delete(event?.target?.value)
    }
    setSelectedStockItem(new Set(stockItem))
  }

  const [selectedWarehouse, setSelectedWarehouse] = useState(new Set())
  const handleCheckboxWarehouseChange = (event: any) => {
    let warehouse = selectedWarehouse

    if (event?.target?.checked) {
      warehouse.add(event?.target?.value)
    } else {
      warehouse.delete(event?.target?.value)
    }
    setSelectedWarehouse(new Set(warehouse))
  }

  const docTypeArray = [{ Name: 'Issue' }, { Name: 'Receipt' }]
  const [selectedDocType, setSelectedDocType] = useState(new Set())
  const handleCheckboxDocType = (event: any) => {
    let docType = selectedDocType

    if (event?.target?.checked) {
      docType.add(event?.target?.value)
    } else {
      docType.delete(event?.target?.value)
    }
    setSelectedDocType(new Set(docType))
  }

  const [selectedTransactionType, setSelectedTransactionType] = useState(
    new Set()
  )
  const handleCheckboxTransactionType = (event: any) => {
    let transactionType = selectedTransactionType

    if (event?.target?.checked) {
      transactionType.add(event?.target?.value)
    } else {
      transactionType.delete(event?.target?.value)
    }
    setSelectedTransactionType(new Set(transactionType))
  }

  const [selectedAccountCode, setSelectedAccountCode] = useState(new Set())
  const handleCheckboxAccountCode = (event: any) => {
    let accountCode = selectedAccountCode

    if (event?.target?.checked) {
      accountCode.add(event?.target?.value)
    } else {
      accountCode.delete(event?.target?.value)
    }
    setSelectedAccountCode(new Set(accountCode))
  }

  const [selectedDepartment, setSelectedDepartment] = useState(new Set())
  const handleCheckboxDepartment = (event: any) => {
    let department = selectedDepartment

    if (event?.target?.checked) {
      department.add(event?.target?.value)
    } else {
      department.delete(event?.target?.value)
    }
    setSelectedDepartment(new Set(department))
  }

  const onSubmit = data => {
    history.push({
      pathname: `/digital-reports/inventory-control/stock-usage-by-department/generated`,
      state: {
        CompanyID: data?.CompanyID ? data?.CompanyID : undefined,
        StartDate: data?.StartDate ? new Date(data.StartDate) : null,
        EndDate: data?.EndDate ? new Date(data?.EndDate) : null,
        StartExpectedDate: data?.StartExpectedDate
          ? new Date(data.StartExpectedDate)
          : null,
        EndExpectedDate: data?.EndExpectedDate
          ? new Date(data?.EndExpectedDate)
          : null,
        DocNo: data?.DocNo ? data.DocNo : undefined,
        DocRef: data?.DocRef ? data?.DocRef : undefined,
        StockCategoryID: selectedStockCategory
          ? Array.from(selectedStockCategory)?.toString()
          : undefined,
        StockItemID: selectedStockItem
          ? Array.from(selectedStockItem)?.toString()
          : undefined,
        WarehouseID: selectedWarehouse
          ? Array.from(selectedWarehouse)?.toString()
          : undefined,
        DocType: selectedDocType
          ? Array.from(selectedDocType)?.toString()
          : undefined,
        TransactionTypeID: selectedTransactionType
          ? Array.from(selectedTransactionType)?.toString()
          : undefined,
        MasterCOAID: selectedAccountCode
          ? Array.from(selectedAccountCode)?.toString()
          : undefined,
        CostCentreID: selectedDepartment
          ? Array.from(selectedDepartment)?.toString()
          : undefined,
        Amount: data?.Amount ? data?.Amount : undefined,
      },
    })
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {EntityCOALoading && <Loading />}
      {MasterCOALoading && <Loading />}
      {StockItemLoading && <Loading />}
      {WarehouseLoading && <Loading />}
      {CostCentreLoading && <Loading />}
      {TransactionTypeLoading && <Loading />}
      {TransactionTypeLoading && <Loading />}
      {StockCategoryNameLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/digital-reports/inventory-control`)}
        smTitle={'Inventory Control'}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Stock Usage By Department', current: true },
        ]}
        rightRouteSegments={[{ name: 'Parameters', current: true }]}
      />
      <ContentWrapper float>
        <CardContents>
          {!CompanyAssignedLoading && !CompanyLoading && (
            <Controller
              render={({ value, onChange }) => {
                const options: CompanyOption[] =
                  !!user?.superUser === false
                    ? getAssignedCompanyByUser
                    : getCompany
                const defVal = getCompany?.filter(
                  x => x?.CompanyID === latestCompany
                )[0]
                return (
                  <Autocomplete
                    options={options || []}
                    getOptionLabel={(option: CompanyOption) => option.Name}
                    fullWidth
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.CompanyID)
                      loadWarehouse({
                        variables: {
                          orderByAsc: 'Name',
                          CompanyID: newValue?.CompanyID,
                        },
                      })
                      loadEntityCOA({
                        variables: { CompanyID: newValue?.CompanyID },
                      })
                      loadCostCentre({
                        variables: {
                          CompanyID: newValue?.CompanyID,
                          orderByAsc: 'Code',
                          IsLastNode: true,
                        },
                      })
                    }}
                    PopperComponent={AccCodeDropdownFullWidth}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.Name}</span>
                          </div>
                        </div>
                      )
                    }}
                    defaultValue={defVal}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.CompanyID?.message}
                            error={errors?.CompanyID ? true : false}
                            label="Company"
                            style={{ width: '100%' }}
                            margin="normal"
                            variant="standard"
                            required
                            defaultValue={defVal}
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Company"
              name="CompanyID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.CompanyID?.message}
              error={errors?.CompanyID ? true : false}
              required
              defaultValue={latestCompany}
            />
          )}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="StartDate"
              label="Start Doc Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('StartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="left"
              defaultValue={editData?.StartDate ?? new Date()}
              helperText={errors?.StartDate?.message}
              error={errors?.StartDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="EndDate"
              label="End Doc Date"
              control={control}
              onChange={(date: Date | null) => {
                setValue('EndDate', date)
              }}
              format="dd/MM/yyyy"
              value={watch('EndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="right"
              defaultValue={editData?.EndDate ?? new Date()}
              helperText={errors?.EndDate?.message}
              error={errors?.EndDate ? true : false}
              showTodayButton
              fullWidth
              required
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxnStartDate"
              label="Trxn Start Date"
              control={control}
              onChange={(date: Date | null) => {}}
              format="dd/MM/yyyy"
              value={watch('TrxnStartDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="left"
              defaultValue={editData?.TrxnStartDate ?? null}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="TrxnEndDate"
              label="Trxn End Date"
              control={control}
              onChange={(date: Date | null) => {
                setValue('TrxnEndDate', date)
              }}
              format="dd/MM/yyyy"
              value={watch('TrxnEndDate')}
              margin="normal"
              allowKeyboardControl
              ref={register}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              className="right"
              defaultValue={editData?.TrxnEndDate ?? null}
              helperText={errors?.TrxnEndDate?.message}
              error={errors?.TrxnEndDate ? true : false}
              showTodayButton
              fullWidth
            />
          </MuiPickersUtilsProvider>

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocNo"
            label="Doc No"
            autoComplete="off"
            control={control}
            fullWidth
            className="left"
            margin="dense"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          <Controller
            as={TextField}
            id="standard-basic"
            name="DocRef"
            label="Doc Ref"
            autoComplete="off"
            control={control}
            fullWidth
            className="right"
            margin="dense"
            ref={register}
            helperText={errors?.DocRef?.message}
            error={errors?.DocRef ? true : false}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={getStockCategory || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.StockCategoryID)
                    })

                    setSelectedStockCategory(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockCategoryID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockCategoryChange(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Category"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockCategoryID"
            label="Category"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={getStockItem || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.StockItemID)
                    })

                    setSelectedStockItem(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.StockItemID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxStockItemChange(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Item"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="StockItemID"
            label="Item"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={watch('CompanyID') ? getWarehouse : []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.WarehouseID)
                    })

                    setSelectedWarehouse(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.WarehouseID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxWarehouseChange(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Warehouse"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="WarehouseID"
            label="Warehouse"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={docTypeArray || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.Name)
                    })

                    setSelectedDocType(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.Name}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDocType(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Doc Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="DocType"
            label="Doc Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={getTransactionType || []}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.TransactionTypeID)
                    })

                    setSelectedTransactionType(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.TransactionTypeID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxTransactionType(event)
                        }}
                      />
                      {`${option?.Name}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Transaction Type"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="TransactionTypeID"
            label="Transaction Type"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={watch('CompanyID') ? masterCOAList : []}
                  getOptionLabel={option => `${option?.Code}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.MasterCOAID)
                    })

                    setSelectedAccountCode(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.MasterCOAID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxAccountCode(event)
                        }}
                      />
                      {`${option?.Code}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Account Code"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="MasterCOAID"
            label="Account Code"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={watch('CompanyID') ? getCostCentre : []}
                  getOptionLabel={option => `${option?.MasterCode}`}
                  fullWidth
                  onChange={(value, newValue: any) => {
                    const selected = new Set()

                    newValue?.map(x => {
                      selected.add(x?.CostCentreID)
                    })

                    setSelectedDepartment(new Set(selected))
                  }}
                  renderOption={(option, { selected }) => (
                    <Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value={option?.CostCentreID}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                        onChange={event => {
                          handleCheckboxDepartment(event)
                        }}
                      />
                      {`${option?.MasterCode}`}
                    </Fragment>
                  )}
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ width: '100%' }}
                          label="Department"
                          margin="normal"
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            name="CostCentreID"
            label="Department"
            margin="normal"
            autoComplete="off"
            fullWidth
            multiline={true}
            control={control}
            select
            ref={register}
          />

          <Controller
            as={<NumberFormat />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="Amount"
            label="Amount"
            value={watch('Amount')}
            autoComplete="off"
            control={control}
            decimalScale={2}
            fixedDecimalScale
            margin="normal"
            ref={register}
          />
        </CardContents>

        <AccountFooter
          options={[
            {
              name: 'Submit',
              onClick: () => {
                handleSubmit(onSubmit)()
              },
              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  )
}
