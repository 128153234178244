import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import theme from '@ifca-root/react-component/src/assets/theme'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment'
import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccountFooter } from 'components/Footer/AccountFooter'
import { TotalAmountFooter } from 'components/Footer/TotalAmountFooter'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  AcctPermission,
  ApprovalStatus,
  GetAuditAdjustmentbyStatusDocument,
  useCheckExistingAccountPeriodLazyQuery,
  useCreateAuditAdjustmentMutation,
  useDocumentListingLazyQuery,
  useGetAuditAdjustmentAdjDateLazyQuery,
  useGetDocNumTitleQuery,
  useUpdateAuditAdjustmentMutation,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { useMenuOption } from 'helpers/Hooks/useMenuOption'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import * as yup from 'yup'
import '../AuditAdjustment.scss'
import { AuditItems } from './AuditComponent/AuditItemComponent'

interface AuditAdjustmentProps {
  CompanyID: string
  AdjYearOrPeriod: string
  Description: string
  RefNo: string
  AuditJItem: any
  DocDate: Date
  debitAmt: number
}

export const AuditAdjustmentForm = (props: any) => {
  const { formMode } = props

  let form, mode
  switch (formMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Draft'
      mode = 'edit'
      break
    case 'approve-reject':
      form = 'Approve'
      mode = 'approve-reject'
      break
    case 'resubmit':
      form = 'Resubmit'
      mode = 'edit'
      break
  }

  let location = useLocation()
  let history = useHistory()
  const { CompanyID, AuditAdjustmentID }: any = useParams()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const editData: any = location?.state
  const [openExitConf, setOpenExitConf] = useState(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [openItem, setOpenItem] = useState<boolean>(false)
  const [itemMode, setItemMode] = useState('')
  const [auditItemDataState, setAuditItemData] = useState([])
  const [errMessage, setErrMessage] = useState(null)
  const [errDialog, setErrDialog] = useState(false)

  const {
    anchorEl,
    setAnchorEl,
    menu,
    handleClick,
    handleClose,
  } = useMenuOption()

  const docAmtReducer = (accumulator, currentValue) =>
    accumulator + currentValue?.DocAmt

  const totalAmount = auditItemDataState?.reduce(docAmtReducer, 0)

  const positiveAmt = auditItemDataState
    ?.filter(v => v?.DocAmt > 0)
    ?.reduce(docAmtReducer, 0)

  const negativeAmt = auditItemDataState
    ?.filter(v => v?.DocAmt < 0)
    ?.reduce(docAmtReducer, 0)

  //Checkbox
  const [state, setState] = useState({
    InterCoFlag: false,
  })

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleAdjTypeChange = (event: any) => {
    setValue('AdjYearOrPeriod', event?.target?.value)
    loadAdjustmentDate({
      variables: {
        AdjYearOrPeriod: event?.target?.value,
        CompanyID: CompanyID,
      },
    })
  }

  const AuditAdjustmentFormSchema = yup.object().shape({
    Description: CommonYupValidation.requireField(SystemMsgs.description()),
    AdjYearOrPeriod: CommonYupValidation.requireField(
      SystemMsgs.adjYearOrPeriod()
    ),
    RefNo: CommonYupValidation.requireField(SystemMsgs.referenceNo()),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    watch,
    reset,
    setError,
    getValues,
    formState: { isDirty, dirtyFields },
  } = useForm<AuditAdjustmentProps>({
    defaultValues: {
      Description: editData ? editData?.Description : '',
      AdjYearOrPeriod: editData ? editData?.AdjYearOrPeriod : '',
      RefNo: editData ? editData?.RefNo : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(AuditAdjustmentFormSchema),
  })

  const {
    files,
    setFiles,
    setPreviewFiles,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment()

  /* -------------------------------------------- */
  /*                     QUERY                    */
  /* -------------------------------------------- */

  const [
    fetchdocuments,
    { loading: documentLoading, data: DocData },
  ] = useDocumentListingLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ DocumentListing }) => {
      if (!editData?.file) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf')
        )
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf'
          )?.map(x => x?.fileURL)
        )
      }
    },
  })

  useEffect(() => {
    if (!documentLoading && formMode !== 'Add') {
      fetchdocuments({ variables: { refID: editData?.AuditAdjustmentID } })
    }
    if (editData?.file) {
      setFiles(editData?.file)
      setPreviewFiles(
        editData?.file?.map(file => {
          return URL.createObjectURL(file)
        })
      )
    }
  }, [formMode, editData?.AuditAdjustmentID, editData])

  const [
    loadAdjustmentDate,
    {
      loading: AuditAdjustmentAdjDateLoading,
      data: { getAuditAdjustmentAdjDate } = { getAuditAdjustmentAdjDate: [] },
    },
  ] = useGetAuditAdjustmentAdjDateLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getAuditAdjustmentAdjDate }) => {
      setValue('DocDate', new Date(getAuditAdjustmentAdjDate?.EndDate))
    },
  })

  const {
    loading: docNumHeaderLoading,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GL_AuditAdjustment',
    },
  })

  useEffect(() => {
    if (formMode === 'edit' && editData) {
      const AuditItems = editData?.AuditAdjustmentItem?.map(item => {
        return {
          AuditAdjustmentID: item?.AuditAdjustmentID,
          MasterCOAID: item?.MasterCOAID,
          MasterCOACode: item?.MasterCOA?.Code,
          MasterCOAName: item?.MasterCOA?.Name,
          DocAmt: item?.DocAmt,
          createdTs: item?.createdTs,
          modTs: item?.modTs,
          CostCentreID: item?.CostCentreID,
          CostCentreName: item?.CostCentre?.Name,
          CostCentreCode: item?.CostCentre?.Code,
          Remark: item?.Remark,
        }
      })
      auditItemDataState.push(...AuditItems)
    }
  }, [formMode, editData])

  const [
    createAuditAdjustment,
    {
      loading: createAuditAdjustmentLoading,
      called: createAuditAdjustmentCalled,
    },
  ] = useCreateAuditAdjustmentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: ({ createAuditAdjustment }) => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.createNewRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/general-ledger/${CompanyID}/audit-adjustment/${createAuditAdjustment?.AuditAdjustmentID}/preview`,
          })
        } else {
          history.push({
            pathname: `/general-ledger/${CompanyID}/audit-adjustment/`,
          })
        }
      }, 500)
      localStorage.removeItem('auditAdjustment')
      localStorage.removeItem('auditAdjustmentItem')
    },
  })

  const [
    updateAuditAdjustment,
    {
      loading: updateAuditAdjustmentLoading,
      called: updateAuditAdjustmentCalled,
    },
  ] = useUpdateAuditAdjustmentMutation({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        if (!!isSubmit) {
          history.push({
            pathname: `/general-ledger/${CompanyID}/audit-adjustment/${AuditAdjustmentID}/preview`,
          })
        } else {
          history.push({
            pathname: `/general-ledger/${CompanyID}/audit-adjustment/`,
          })
        }
      }, 500)
      localStorage.removeItem('auditAdjustment')
      localStorage.removeItem('auditAdjustmentItem')
    },
  })

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )

  const [errorDia, setErrorDia] = useState<boolean>(false)

  const onSubmit = (data, status) => {
    if (formMode === 'add') {
      if (
        getDocumentNumberHeader?.filter(
          x => x?.RefTable === 'GL_AuditAdjustment'
        )?.length === 0
      ) {
        // setErrorDia(true)
        setOpenSnackBar(true)
        setSnackBarMsg(SystemMsgs.errorNumberingStructure())
      } else {
        createAuditAdjustment({
          variables: {
            input: {
              CompanyID: CompanyID,
              AdjYearOrPeriod: data.AdjYearOrPeriod,
              Description: data.Description,
              DocDate: data?.DocDate,
              RefNo: data.RefNo,
              ApprovalStatus: statusInput(status),
              submittedTs: new Date(),
              Attachment: files,
            },
            itemInput: auditItemDataState?.map(x => {
              return {
                MasterCOAID: x?.MasterCOAID,
                CostCentreID: x?.CostCentreID,
                Remark: x?.Remark,
                DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
                Sequence: x?.Sequence,
              }
            }),
          },
          refetchQueries: [
            {
              query: GetAuditAdjustmentbyStatusDocument,
              variables: {
                CompanyID: CompanyID,
                StatusArr: ['ACTIVE'],
              },
            },
          ],
        })
      }
    } else if (formMode !== 'add')
      updateAuditAdjustment({
        variables: {
          input: {
            AuditAdjustmentID: AuditAdjustmentID,
            AdjYearOrPeriod: data.AdjYearOrPeriod,
            CompanyID: CompanyID,
            Description: data.Description,
            DocDate: data?.DocDate,
            RefNo: data.RefNo,
            ApprovalStatus: statusInput(status),
            submittedTs: new Date(),
            Attachment: files,
          },
          itemInput: auditItemDataState?.map(x => {
            return {
              MasterCOAID: x?.MasterCOAID,
              CostCentreID: x?.CostCentreID,
              Remark: x?.Remark,
              DocAmt: parseFloat(amtNumStr(x?.DocAmt)),
              Sequence: x?.Sequence,
            }
          }),
        },
        refetchQueries: [
          {
            query: GetAuditAdjustmentbyStatusDocument,
            variables: {
              CompanyID: CompanyID,
              StatusArr: ['ACTIVE'],
            },
          },
        ],
      })
  }

  // Status

  let statusInput = mode => {
    let temp
    switch (mode) {
      case 'submit':
        temp = ApprovalStatus.Submit
        break
      case 'draft':
        temp = ApprovalStatus.Active
        break
      case 'approve':
        temp = ApprovalStatus.Approved
        break
      case 'reject':
        temp = ApprovalStatus.Rejected
        break
    }
    return temp
  }

  // Button submit

  const reducer = (accumulator, currentValue) => accumulator + currentValue

  const watchvalue = watch(['AuditJItem'])

  // const totalAmount = watchvalue?.AuditJItem?.map(x =>
  //   parseFloat(amtNumStr(x?.DocAmt))
  // )?.reduce(reducer, 0)

  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker()
  /**ACL */

  const createUpdateCalled = editData
    ? updateAuditAdjustmentCalled
    : createAuditAdjustmentCalled

  const draftFooterOption = {
    name: 'Save as Draft',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'draft'))()
    },
    disabled: handlePermDisabled({
      companyID: CompanyID,
      permEnum: AcctPermission.GeneralLedgerAuditAdjustmentDraft,
    }),
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  const rejectFooterOption = {
    name: 'Save',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'reject'))()
    },
    disabled:
      !!errors?.AdjYearOrPeriod || !!errors?.RefNo || !!errors?.Description
        ? true
        : handlePermDisabled({
            companyID: CompanyID,
            permEnum: AcctPermission.GeneralLedgerAuditAdjustmentUpdate,
          }),
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  const submitFooterOption = {
    name: 'Submit',
    onClick: () => {
      handleSubmit(data => !createUpdateCalled && onSubmit(data, 'submit'))()
      setIsSubmit(true)
    },
    disabled:
      parseFloat(totalAmount) === 0 && auditItemDataState?.length > 1
        ? false
        : true,
    // disabled:
    //   parseFloat(totalAmount) === 0 && watchvalue?.AuditJItem?.length > 1
    //     ? false
    //     : true,
    color: 'primary',
    props: {
      type: 'submit',
    },
  }

  let footerOptions: any[]
  if (editData?.mode === 'resubmit') {
    footerOptions = [rejectFooterOption, submitFooterOption]
  } else {
    footerOptions = [draftFooterOption, submitFooterOption]
  }
  const [
    loadExistAccountPeriodChecking,
    {
      loading: CheckExistingAccountPeriodLoading,
      data: { CheckExistingAccountPeriod } = {
        CheckExistingAccountPeriod: [],
      },
    },
  ] = useCheckExistingAccountPeriodLazyQuery({
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMessage(error)
      setErrDialog(true)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  })

  useEffect(() => {
    loadExistAccountPeriodChecking({
      variables: {
        CompanyID: CompanyID,
      },
    })
  }, [])

  const checkMonth = CheckExistingAccountPeriod['LastMonth']
  const checkYear = CheckExistingAccountPeriod['LastYear']

  const AdjYearOrPeriod = [
    { AdjYearOrPeriod: 'Previous Month Adjustment' },
    { AdjYearOrPeriod: 'Previous Year Adjustment' },
  ]

  const adjTypeArr = [
    {
      value: 'Previous Month Adjustment',
      disabled: checkMonth === false,
    },
    {
      value: 'Previous Year Adjustment',
      disabled: checkYear === false,
    },
  ]

  const handleDeleteItem = index => {
    if (menu?.index > -1) {
      auditItemDataState.splice(menu?.index, 1)
    }
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */

  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: editData,
      formMode: formMode,
      // for item
      itemArr: auditItemDataState,
      itemSuffixID: 'AuditAdjustmentItemID',
      itemTableName: 'AuditAdjustmentItem',
    })

  return (
    <>
      {docNumHeaderLoading && <Loading />}
      {createAuditAdjustmentLoading && <Loading />}
      {updateAuditAdjustmentLoading && <Loading />}
      {CheckExistingAccountPeriodLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (hasChanges() === true) {
            setOpenExitConf(true)
          } else {
            history.push(`/general-ledger/${CompanyID}/audit-adjustment`)
            localStorage.removeItem('auditAdjustment')
            localStorage.removeItem('auditAdjustmentItem')
          }
        }}
        smTitle={'General Ledger'}
        title={user?.companyName}
        routeSegments={[
          { name: 'General Ledger' },
          { name: 'General Ledger' },
          { name: 'Audit Adjustment', current: true },
        ]}
        rightRouteSegments={[
          {
            name:
              formMode === 'add'
                ? 'New'
                : formMode === 'approve-reject'
                ? 'Approve/Reject'
                : 'Edit',
            // name:
            //   mode === 'add'
            //     ? 'New'
            //     : mode === 'approve-reject'
            //     ? 'Approve/Reject'
            //     : 'Edit',
            current: true,
          },
        ]}
      />
      <ContentWrapper float footer>
        <CardContents
          section={{
            header: {
              title: 'Audit Adjustment Details',
            },
          }}
        >
          <Controller
            render={({ onChange, value }) => (
              <TextField
                label="Adjustment Type"
                margin="normal"
                select
                required
                value={value}
                onChange={e => {
                  onChange(e)
                  handleAdjTypeChange(e)
                }}
                style={{ marginBottom: '-100px' }}
              >
                {adjTypeArr?.map((el, index) => (
                  <MenuItem
                    id="contract-select"
                    key={index}
                    value={el?.value}
                    disabled={el?.disabled}
                  >
                    {el?.value}
                  </MenuItem>
                ))}
              </TextField>
            )}
            // required
            // select
            name="AdjYearOrPeriod"
            autoComplete="off"
            control={control}
            fullWidth
            ref={register()}
            helperText={errors?.AdjYearOrPeriod?.message}
            error={errors?.AdjYearOrPeriod ? true : false}
            // defaultValue={
            //   mode === 'edit' || mode === 'approve-reject'
            //     ? editData?.AdjYearOrPeriod
            //     : ''
            // }
            defaultValue={
              formMode === 'edit' || formMode === 'approve-reject'
                ? editData?.AdjYearOrPeriod
                : ''
            }
            disabled={formMode === 'approve-reject'}
          />

          <div style={{ visibility: 'hidden' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                render={({ onChange, value }) => (
                  <KeyboardDatePicker
                    label="Adjustment Date"
                    format="dd MMM yyyy"
                    showTodayButton
                    allowKeyboardControl
                    margin="normal"
                    required
                    value={watch('DocDate')}
                    disabled
                    onChange={e => {
                      onChange(e)
                    }}
                  ></KeyboardDatePicker>
                )}
                name="DocDate"
                control={control}
                format="dd MMM yyyy"
                value={watch('DocDate')}
                // defaultValue={editData?.DocDate ? editData?.DocDate : null}
                defaultValue={
                  formMode === 'edit' || formMode === 'approve-reject'
                    ? editData?.DocDate
                    : editData?.DocDate
                }
                fullWidth
                required
                disabled
                margin="normal"
                inputRef={register}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <Controller
            as={TextField}
            //className="left"
            id="standard-basic"
            name="RefNo"
            label="Reference No."
            autoComplete="off"
            control={control}
            fullWidth
            required
            //className="left"
            margin="dense"
            helperText={errors?.RefNo?.message}
            error={errors?.RefNo ? true : false}
            ref={register}
            // defaultValue={editData?.DocNo}
            // defaultValue={
            //   mode === 'edit' || mode === 'approve-reject'
            //     ? editData?.RefNo
            //     : ''
            // }
            defaultValue={
              formMode === 'edit' || formMode === 'approve-reject'
                ? editData?.RefNo
                : ''
            }
            disabled={formMode === 'approve-reject'}
          />

          <Controller
            // InputLabelProps={{
            //   shrink: true,
            // }}
            as={TextField}
            // <TextField
            //id="standard-basic"
            name="Description"
            label="Description"
            required
            autoComplete="off"
            control={control}
            fullWidth
            //margin="dense"
            helperText={errors?.Description?.message}
            error={errors?.Description ? true : false}
            ref={register}
            // defaultValue={editData?.Description}
            // defaultValue={
            //   mode === 'edit' || mode === 'approve-reject'
            //     ? editData?.Description
            //     : ''
            // }
            defaultValue={
              formMode === 'edit' || formMode === 'approve-reject'
                ? editData?.Description
                : ''
            }
            disabled={formMode === 'approve-reject'}
          />
          <FileUploadInput
            placeholder={previewFiles.length === 0 ? 'Attachment' : null}
            label={previewFiles.length > 0 ? 'Attachment' : null}
            files={files}
            onHandleUploadChange={handleUploadChange}
            multiple
            // accept={
            //   'application/msword, application/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            // }
            // mapData={'application'}
            imagePreview={
              <>
                {previewFiles?.map((v, i) => (
                  <UploadPreview
                    remove
                    key={v}
                    src={v}
                    onClick={() => removeFile(i)}
                    mediaType={
                      files[i]?.type ?? DocData?.DocumentListing[i]?.mediaType
                    }
                  />
                ))}
              </>
            }
          />
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Adjustment Detail',
              onClickAction: () => {
                setItemMode('add')
                setOpenItem(true)
              },

              icon:
                formMode === 'add' || formMode === 'edit' ? (
                  <AddIcon
                    htmlColor="white"
                    fontSize="default"
                    style={{
                      width: '1.3rem',
                      height: '1.3rem',
                      margin: '0',
                      background: theme.palette.primary.main,
                      boxShadow: `1px 1px 4px 0px ${theme.palette.primary.main}`,
                      borderRadius: '3px',
                      color: 'rgba(224,234,254,100)',
                      marginTop: '10px',
                    }}
                  />
                ) : null,
            },
          }}
        >
          <div className="rm-padding table-wrap ">
            {auditItemDataState === undefined ||
            auditItemDataState?.length === 0 ? (
              <EmptyList
                title="No Record Found"
                subtitle="Add New Record now."
              />
            ) : (
              auditItemDataState
                ?.sort(function(a, b) {
                  return a?.Sequence < b?.Sequence ? -1 : 1
                })
                ?.map((v, index) => {
                  return (
                    <>
                      {/* ORIGINAL */}
                      <Grid spacing={1} container className="table-content">
                        <Grid
                          item
                          xs={1}
                          style={{
                            placeSelf: 'start',
                          }}
                        >
                          <span className="xxTitle">{`${index + 1}.`}</span>
                        </Grid>

                        <Grid
                          item
                          xs={7}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                          }}
                        >
                          <div>
                            <div
                              className="xxTitle text-noflow"
                              style={{ lineBreak: 'anywhere' }}
                            >
                              {v?.MasterCOACode}&nbsp;{v?.MasterCOAName}
                            </div>
                            <div className="desc text-noflow">
                              {v?.CostCentreName}
                            </div>
                            <div className="desc text-noflow">{v?.Remark}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          style={{
                            placeSelf: 'start',
                            marginTop: '8px',
                            marginRight: 'auto',
                            textAlign: 'right',
                            flexWrap: 'wrap',
                            display: 'flex',
                            justifyContent: 'end',
                          }}
                        >
                          <div>
                            <div className="xxTitle">{amtStr(v?.DocAmt)}</div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={1}
                          style={{
                            marginTop: '0px',
                          }}
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            aria-controls="menu-list"
                            aria-haspopup="true"
                            onClick={e =>
                              handleClick(e, v?.AuditAdjustmentItemID, index, v)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Divider
                        variant="fullWidth"
                        style={{ background: '#E4E4E4' }}
                      ></Divider>

                      <Menu
                        id="menu-list"
                        key={index}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        onClick={handleClose}
                      >
                        <MenuItem
                          onClick={() => {
                            setItemMode('edit')
                            setOpenItem(true)
                          }}
                        >
                          <span className="mdDesc">Edit</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleDeleteItem(index)}>
                          <span className="mdDesc">Delete</span>
                        </MenuItem>
                      </Menu>
                    </>
                  )
                })
            )}
          </div>
        </CardContents>
      </ContentWrapper>

      {!!openItem && (
        <AuditItems
          openItem={openItem}
          setOpenItem={setOpenItem}
          auditItemData={auditItemDataState}
          CompanyID={CompanyID}
          AuditAdjustmentID={AuditAdjustmentID}
          formMode={formMode}
          detailMode={itemMode}
          menu={menu}
          AuditAdjustmentItemID={
            itemMode === 'edit' ? menu?.obj?.AuditAdjustmentItemID : ''
          }
        />
      )}
      {footerOptions?.length > 0 ? (
        <AccountFooter options={[...footerOptions]} />
      ) : null}

      <TotalAmountFooter
        debitCreditInfo={true}
        debitAmt={amtStr(positiveAmt) ?? '0.00'}
        creditAmt={amtStr(Math.abs(negativeAmt)) ?? '0.00'}
      />

      <CommonDialog
        fullWidth={true}
        open={errorDia}
        onClose={() => setErrorDia(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle flex-space"
                        style={{ color: 'red' }}
                      >
                        {'Document Numbering is empty'}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <div>
              <span>{'Please setup Document Numbering to Submit'}</span>
            </div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Close',
                props: {
                  onClick: () => setErrorDia(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          history.push(`/general-ledger/${CompanyID}/audit-adjustment`)
          localStorage.removeItem('auditAdjustment')
          localStorage.removeItem('auditAdjustmentItem')
        }}
        // hasInfo={hasInfo}
      />
      <ErrorDialog
        errorDia={errDialog}
        setErrorDia={setErrDialog}
        errorMsg={errMessage}
        errorHeaderMsg={'Error!'}
      />
    </>
  )
}
