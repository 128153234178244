import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import ReportViewer, {
  accountxReportUrl,
} from 'containers/DigitalReportModule/ReportViewer'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'

export const ARDebtorControlDetail = () => {
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const accountID = user.accountID
  let location = useLocation()
  const { CompanyID }: any = useParams()
  const editData = location?.state as any

  const parameters = [
    {
      name: 'accountid',
      value: accountID,
    },
    {
      name: 'companyid',
      value: editData?.CompanyID,
    },

    // {
    //   name: 'debtortypeid',
    //   value: editData?.DebtorTypeID,
    // },
    {
      name: 'debtoraccountid',
      value: editData?.DebtorAccountID,
    },
    {
      name: 'startdate',
      value: new Date(editData?.StartDate.setHours(0, 0, 0)).toISOString(),
    },
    {
      name: 'enddate',
      value: new Date(editData?.EndDate.setHours(0, 0, 0)).toISOString(),
    },
  ]

  const generatedurl = (reportName, parameters) => {
    let reporturl = reportName

    parameters?.map((x, index) => {
      const symbol = index === 0 ? '?' : '&'
      if (x?.value !== undefined)
        reporturl = reporturl + symbol + x?.name + '=' + x?.value
    })
    return reporturl
  }

  return (
    <>
      <MainHeader
        onClick={() => history.push(`/digital-reports/account-receivable`)}
        mainBtn="close"
        smTitle="Accounts Receivable"
        title={user?.companyName}
        routeSegments={[
          { name: 'Submenu' },
          { name: 'Debtor Control Detail', current: true },
        ]}
        rightRouteSegments={[{ name: 'Report', current: true }]}
      />
      <ContentWrapper style={{ maxHeight: '100%' }}>
        {ReportViewer({
          url: generatedurl('DebtorControlDetailReport', parameters),
          host: accountxReportUrl,
          excludedExportFormat: [],
        })}
      </ContentWrapper>
    </>
  )
}
