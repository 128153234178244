import React from 'react'
import { CashInflowVSOutflowByMonth } from './CashInflowVSOutflowByMonth'
import { CashInflowVSOutflowByMonthParamsForm } from './CashInflowVSOutflowByMonthParamsForm'
import { CashLedgerListingParamsForm } from './CashLedgerListingParamsForm'
import { CashLedgerListingReport } from './CashLedgerListingReport'
import { CBDigitalReportSubmenu } from './CBDigitalReportSubmenu'
import { BankReconciliationStatementReport } from './BankRecociliationStatementReport'
import { BankReconciliationStatementParamsForm } from './BankReconciliationStatementParamsForm'

const cbDigitalReportRoutes = [
  {
    props: {
      exact: true,
      path: '/digital-reports/cash-book',
    },
    component: <CBDigitalReportSubmenu mode="cash-book" />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/cash-book/cash-ledger-report/generated',
    },
    component: <CashLedgerListingReport />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/cash-book/cash-ledger-report/parameters',
    },
    component: <CashLedgerListingParamsForm />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/cash-book/cash-inflow-outflow-report/generated',
    },
    component: <CashInflowVSOutflowByMonth />,
  },
  {
    props: {
      exact: true,
      path: '/digital-reports/cash-book/cash-inflow-outflow-report/parameters',
    },
    component: <CashInflowVSOutflowByMonthParamsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/cash-book/bank-reconciliation-statement-report/generated',
    },
    component: <BankReconciliationStatementReport />,
  },
  {
    props: {
      exact: true,
      path:
        '/digital-reports/cash-book/bank-reconciliation-statement-report/parameters',
    },
    component: <BankReconciliationStatementParamsForm />,
  },
]

export default cbDigitalReportRoutes
