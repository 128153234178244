import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import Advance from 'assets/icons/ARAPModule/AccountPayable/advances-posting.svg'
import CreditNote from 'assets/icons/ARAPModule/AccountPayable/credit-note-posting.svg'
import DebitNote from 'assets/icons/ARAPModule/AccountPayable/Group 64855.svg'
import Invoices from 'assets/icons/ARAPModule/AccountPayable/invoices-posting.svg'
import Payment from 'assets/icons/ARAPModule/AccountPayable/payment-posting.svg'
import Refund from 'assets/icons/ARAPModule/AccountPayable/refund-posting.svg'
import Debtor from 'assets/icons/ARAPModule/debtor-creditor-acc.svg'
import DebtorProfile from 'assets/icons/ARAPModule/GeneralSetting/creditor-profile.svg'
import DigitalReports from 'assets/icons/digital-reporting.svg'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { SwitchDialog } from 'components/Switch/SwitchDialog'
import AppContext from 'containers/App/Store/AppContext'
import {
  AcctPermission,
  RecordStatus,
  useGetApSubmenuSummaryLazyQuery,
  useGetAssignedCompanyQuery,
  useGetCompanyNameQuery,
  useGetCreditorAccountCompanyAssignmentNameLazyQuery,
  useGetCreditorSubmenuSummaryLazyQuery,
  useGetCreditorTrxSummaryLazyQuery,
} from 'generated/graphql'
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker'
import { formatDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

export const APSubmenu = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any)
  let history = useHistory()
  const { CompanyID, CreditorAccountID }: any = useParams()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [change, setSwitch] = useState(false)
  const [homeCompanyID, setHomeCompanyID] = useState<string>(CompanyID)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  if (globalState?.mode === '') {
    dispatch({
      type: 'mode',
      payload: 'general-ledger',
    })
  }

  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  useEffect(() => {
    document.title = `AX - Acc Payable`
  }, [])

  // ACCOUNTX API CALLS
  const {
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RecordStatus: RecordStatus.Active,
    },
  })

  let userAccDetail = {
    ...user,
    companyID: getCompany[0]?.CompanyID,
    companyName: getCompany[0]?.Name,
  }

  // USER INFO
  localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail))

  //Creditor Account Company Assignment//

  const [
    loadCreditorAccount,
    {
      loading: CreditorAccountCompanyAssignmentLoading,
      error: CreditorAccountCompanyAssignmentError,
      data: { getCreditorAccountCompanyAssignment } = {
        getCreditorAccountCompanyAssignment: [],
      },
    },
  ] = useGetCreditorAccountCompanyAssignmentNameLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!!CreditorAccountID) {
      loadCreditorAccount({
        variables: {
          CompanyID: CompanyID,
          CreditorAccountID: CreditorAccountID,
        },
      })
    }
  }, [CreditorAccountID])

  const {
    loading: CompanyAssignedLoading,
    error: CompanyAssignedError,
    data: { getAssignedCompanyByUser } = { getAssignedCompanyByUser: [] },
  } = useGetAssignedCompanyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadAPSubmenuSummary,
    {
      loading: APSubmenuSummaryLoading,
      data: {
        apAdvanceCount,
        apInvoiceCount,
        apCreditNoteCount,
        apCreditorCreditNoteCount,
        apDebitNoteCount,
        apCreditorDebitNoteCount,
        apPaymentCount,
        apBatchPaymentCount,
        apRefundCount,
        creditorAccountCount,
      } = {
        apAdvanceCount: 0,
        apInvoiceCount: 0,
        apCreditNoteCount: 0,
        apCreditorCreditNoteCount: 0,
        apDebitNoteCount: 0,
        apCreditorDebitNoteCount: 0,
        apPaymentCount: 0,
        apBatchPaymentCount: 0,
        apRefundCount: 0,
        creditorAccountCount: 0,
      },
    },
  ] = useGetApSubmenuSummaryLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  const [
    loadCreditorSubmenuSummary,
    {
      loading: CreditorSubmenuSummaryLoading,
      data: {
        apAdvanceTotalAmt,
        apInvoiceTotalAmt,
        apCreditNoteTotalAmt,
        apCreditorCreditNoteTotalAmt,
        apDebitNoteTotalAmt,
        apPaymentTotalAmt,
        apRefundTotalAmt,
      } = {
        apAdvanceTotalAmt: 0,
        apInvoiceTotalAmt: 0,
        apCreditNoteTotalAmt: 0,
        apCreditorCreditNoteTotalAmt: 0,
        apDebitNoteTotalAmt: 0,
        apPaymentTotalAmt: 0,
        apRefundTotalAmt: 0,
      },
    },
  ] = useGetCreditorSubmenuSummaryLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  const CreditorAccountTotalAmount =
    apInvoiceTotalAmt?.total -
    apCreditNoteTotalAmt?.total +
    apDebitNoteTotalAmt?.total -
    apPaymentTotalAmt?.total +
    apRefundTotalAmt?.total
  // apAdvanceTotalAmt?.total -

  const [
    loadCreditorTrxSummary,
    {
      loading: CreditorTrxSummaryLoading,
      data: {
        apAdvanceLatestUpdate,
        apInvoiceLatestUpdate,
        apCreditNoteLatestUpdate,
        apCreditorCreditNoteLatestUpdate,
        apDebitNoteLatestUpdate,
        apCreditorDebitNoteLatestUpdate,
        apPaymentLatestUpdate,
        apRefundLatestUpdate,
      } = {
        apAdvanceLatestUpdate: undefined,
        apInvoiceLatestUpdate: undefined,
        apCreditNoteLatestUpdate: undefined,
        apCreditorCreditNoteLatestUpdate: undefined,
        apDebitNoteLatestUpdate: undefined,
        apCreditorDebitNoteLatestUpdate: undefined,
        apPaymentLatestUpdate: undefined,
        apRefundLatestUpdate: undefined,
      },
    },
  ] = useGetCreditorTrxSummaryLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (CreditorAccountID) {
      loadCreditorSubmenuSummary({
        variables: {
          CompanyID: CompanyID,
          CreditorAccountID: CreditorAccountID,
        },
      })
      loadCreditorTrxSummary({
        variables: {
          CompanyID: CompanyID,
          CreditorAccountID: CreditorAccountID,
        },
      })
    } else {
      loadAPSubmenuSummary({
        variables: { CompanyID: CompanyID },
      })
    }
  }, [CreditorAccountID, CompanyID])

  const { handlePermHidden } = usePermissionChecker()

  /* -------------------------------------------- */
  /*                  AP SUBMENU                  */
  /* -------------------------------------------- */
  const creditorAcc = {
    path: `/account-payable/${CompanyID}/creditor-account`,
    icon: Debtor,
    title: `Creditor Accounts`,
    label1: `${creditorAccountCount?.count} Creditor`,
    deployed: true,
    permission: AcctPermission.AccPayableCreditorProfileView,
  }

  const advance = {
    path: `/account-payable/${CompanyID}/advance`,
    icon: Advance,
    title: `Advances`,
    draft: apAdvanceCount?.draft,
    submitted: apAdvanceCount?.submitted,
    permission: AcctPermission.AccPayableAdvancesView,
  }

  const invoice = {
    path: `/account-payable/${CompanyID}/invoice`,
    icon: Invoices,
    title: `Invoices`,
    draft: apInvoiceCount?.draft,
    submitted: apInvoiceCount?.submitted,
    permission: AcctPermission.AccPayableInvoicesView,
  }

  const creditNote = {
    path: `/account-payable/${CompanyID}/credit-note`,
    icon: CreditNote,
    title: `Credit Notes`,
    draft: apCreditNoteCount?.draft,
    submitted: apCreditNoteCount?.submitted,
    permission: AcctPermission.AccPayableCreditNotesView,
    // disabled: true,
  }

  const creditNoteFC = {
    path: `/account-payable/${CompanyID}/creditor-credit-note`,
    icon: CreditNote,
    title: `Credit Notes from Creditor`,
    draft: apCreditorCreditNoteCount?.draft,
    submitted: apCreditorCreditNoteCount?.submitted,
    permission: AcctPermission.AccPayableCreditorCreditNotesView,
    // disabled: true,
  }

  const debitNote = {
    path: `/account-payable/${CompanyID}/debit-note`,
    icon: DebitNote,
    title: `Debit Notes`,
    draft: apDebitNoteCount?.draft,
    submitted: apDebitNoteCount?.submitted,
    permission: AcctPermission.AccPayableDebitNotesView,
  }

  const debitNoteFC = {
    path: `/account-payable/${CompanyID}/creditor-debit-note`,
    icon: DebitNote,
    title: `Debit Notes from Creditor`,
    draft: apCreditorDebitNoteCount?.draft,
    submitted: apCreditorDebitNoteCount?.submitted,
    permission: AcctPermission.AccPayableCreditorDebitNotesView,
  }

  const payment = {
    path: `/account-payable/${CompanyID}/payment`,
    icon: Payment,
    title: `Payments`,
    draft: apPaymentCount?.draft,
    submitted: apPaymentCount?.submitted,
    permission: AcctPermission.AccPayablePaymentView,
  }

  const batchPayment = {
    path: `/account-payable/${CompanyID}/batch-payment`,
    icon: Payment,
    title: `Batch Payments`,
    draft: apBatchPaymentCount?.draft,
    submitted: apBatchPaymentCount?.submitted,
    permission: AcctPermission.AccPayableBatchPaymentView,
  }

  const refund = {
    path: `/account-payable/${CompanyID}/refund`,
    icon: Refund,
    title: `Refunds`,
    draft: apRefundCount?.draft,
    submitted: apRefundCount?.submitted,
    permission: AcctPermission.AccPayableRefundView,
  }

  const digitalReport = {
    path: `/account-payable/${CompanyID}/digital-report`,
    icon: DigitalReports,
    title: `Digital Reports`,
    label1: `9 Reports available`,
    permission: AcctPermission.AccPayableDigitalReportView,
  }

  const glExport = {
    path: `/account-payable/${CompanyID}/gl-export`,
    icon: DigitalReports,
    title: `GL Export`,
    label1: ` `,
    data1: ' ', //hardcode
    deployed: true,
    disabled: true,
    // permission:
  }

  /* -------------------------------------------- */
  /*           CREDITOR ACCOUNT SUBMENU           */
  /* -------------------------------------------- */
  const creditorProfile = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/detail`,
    icon: DebtorProfile,
    title: `Creditor Profile`,
    title2: ``,
    label1: `Last updated on `,
    deployed: true,
    permission: AcctPermission.AccPayableCreditorProfileView,

    data1: `${formatDate(
      getCreditorAccountCompanyAssignment[0]?.CreditorAccount?.modTs ??
        getCreditorAccountCompanyAssignment[0]?.CreditorAccount?.createdTs
    )}.`,
  }

  const creditorInvoice = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/invoice`,
    icon: Invoices,
    title: `Invoices`,
    title2: apInvoiceTotalAmt?.total,
    label1: `Last invoice date on `,
    deployed: true,
    permission: AcctPermission.AccPayableInvoicesView,
    data1: `${formatDate(apInvoiceLatestUpdate?.latestUpdate)}.`,
  }

  const creditorCreditNote = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/credit-note`,
    icon: CreditNote,
    title: `Credit Note`,
    title2: apCreditNoteTotalAmt?.total,
    label1: `Last credit note date on `,
    deployed: true,
    permission: AcctPermission.AccPayableCreditNotesView,
    data1: `${formatDate(apCreditNoteLatestUpdate?.latestUpdate)}.`,
  }

  const creditorCreditNoteFC = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/creditor-credit-note`,
    icon: CreditNote,
    title: `Credit Note from Creditor`,
    title2: apCreditorCreditNoteTotalAmt?.total,
    label1: `Last credit note date on `,
    deployed: true,
    permission: AcctPermission.AccPayableCreditorCreditNotesView,
    data1: `${formatDate(apCreditorCreditNoteLatestUpdate?.latestUpdate)}.`,
  }

  const creditorDebitNote = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/debit-note`,
    icon: DebitNote,
    title: `Debit Note`,
    title2: apDebitNoteTotalAmt?.total,
    label1: `Last debit note date on `,
    deployed: true,
    permission: AcctPermission.AccPayableDebitNotesView,
    data1: `${formatDate(apDebitNoteLatestUpdate?.latestUpdate)}.`,
  }
  const creditorDebitNoteFC = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/creditor-debit-note`,
    icon: DebitNote,
    title: `Debit Note from Creditor`,
    title2: apDebitNoteTotalAmt?.total,
    label1: `Last debit note date on `,
    deployed: true,
    permission: AcctPermission.AccPayableCreditorDebitNotesView,
    data1: `${formatDate(apCreditorDebitNoteLatestUpdate?.latestUpdate)}.`,
  }

  const creditorAdvance = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/advance`,
    icon: Advance,
    title: `Advances`,
    title2: apAdvanceTotalAmt?.total,
    label1: `Last advance date on `,
    deployed: true,
    permission: AcctPermission.AccPayableAdvancesView,
    data1: `${formatDate(apAdvanceLatestUpdate?.latestUpdate)}.`,
  }

  const creditorPayment = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/payment`,
    icon: Payment,
    title: `Payment`,
    title2: apPaymentTotalAmt?.total,
    label1: `Last payment date on `,
    deployed: true,
    permission: AcctPermission.AccPayablePaymentView,
    data1: `${formatDate(apPaymentLatestUpdate?.latestUpdate)}.`,
  }

  const creditorRefund = {
    path: `/account-payable/${CompanyID}/creditor-account/creditor-profile/${CreditorAccountID}/refund`,
    icon: Refund,
    title: `Refund`,
    title2: apRefundTotalAmt?.total,
    label1: `Last refund date on `,
    deployed: true,
    permission: AcctPermission.AccPayableRefundView,
    data1: `${formatDate(apRefundLatestUpdate?.latestUpdate)}.`,
  }

  let mainMenuList = [],
    headerTitle,
    RETURN_ROUTES

  if (!CreditorAccountID) {
    mainMenuList = [
      creditorAcc,
      advance,
      invoice,
      creditNote,
      creditNoteFC,
      debitNote,
      debitNoteFC,
      payment,
      batchPayment,
      refund,
      // digitalReport,
      // glExport,
    ]
    headerTitle = 'Accounts Payable'
    RETURN_ROUTES = `/account-payable/${CompanyID}`
  } else {
    mainMenuList = [
      creditorProfile,
      creditorAdvance,
      creditorInvoice,
      creditorCreditNote,
      creditorCreditNoteFC,
      creditorDebitNote,
      creditorDebitNoteFC,
      creditorPayment,
      creditorRefund,
    ]
    headerTitle = 'Accounts Payable'
    RETURN_ROUTES = `/account-payable/${CompanyID}/creditor-account`
  }

  return (
    <>
      {CompanyLoading && <Loading />}
      {CreditorAccountCompanyAssignmentLoading && <Loading />}
      {CompanyAssignedLoading && <Loading />}
      {APSubmenuSummaryLoading && <Loading />}
      {CreditorSubmenuSummaryLoading && <Loading />}
      {CreditorTrxSummaryLoading && <Loading />}

      <MainHeader
        mainBtn={'back'}
        // onClick={
        //   creditordebtorType === 'debtor-account' ||
        //   creditordebtorType === 'creditor-account'
        //     ? () =>
        //         history.push(
        //           `/account-payable/${CompanyID}/creditor-account`
        //         )
        //     : handleDrawer
        // }

        onClick={
          CreditorAccountID
            ? () =>
                history.push(`/account-payable/${CompanyID}/creditor-account`)
            : () => history.push(`/`)
        }
        smTitle={`${headerTitle}`}
        title={getCompany[0]?.Name}
        onClickAction={() => setSwitch(true)}
        // sideBtn="switch"
        sideBtn="company"
        rightCustomIcon={getCompany[0]?.Logo?.fileURL}
        routeSegments={
          CreditorAccountID
            ? [
                { name: 'AP' },
                { name: 'Main Menu' },
                { name: 'Creditor Account', current: true },
              ]
            : [{ name: 'Main Menu', current: true }]
        }
      />

      {CreditorAccountID ? (
        <DynamicSubHeader
          title={
            <span>
              <span style={{ fontWeight: 'bold' }}>
                {
                  getCreditorAccountCompanyAssignment[0]?.CreditorAccount
                    ?.CompanyName
                }
              </span>
            </span>
          }
          rightText={
            <>
              <span>
                <>
                  <span className="c-orange ">
                    {getCreditorAccountCompanyAssignment[0]?.CreditorAccount
                      ?.TotalOutstandingAmt < 0
                      ? `(${amtStr(
                          getCreditorAccountCompanyAssignment[0]
                            ?.CreditorAccount?.TotalOutstandingAmt * -1
                        )})`
                      : amtStr(
                          getCreditorAccountCompanyAssignment[0]
                            ?.CreditorAccount?.TotalOutstandingAmt
                        )}
                  </span>
                </>
              </span>
            </>
          }
        />
      ) : null}

      <ContentWrapper
        footer={CreditorAccountID ? true : false}
        singleDynamicInfo={CreditorAccountID ? true : false}
      >
        <List className="core-list">
          {mainMenuList.map((el, index) => {
            if (
              handlePermHidden({
                permEnum: el?.permission,
              })
            )
              return (
                <ListItem
                  key={index}
                  onClick={() =>
                    !el?.disabled ? history.push(el?.path) : null
                  }
                  disabled={el?.disabled ? el?.disabled : null}
                >
                  <IconItem
                    image={el?.icon}
                    imageType="svg"
                    badgeType="default"
                  />
                  <ListItemText
                    style={{
                      textAlign: 'right',
                      marginLeft: '5px',
                    }}
                    primary={
                      CreditorAccountID && el?.title !== 'Creditor Profile' ? (
                        <>
                          <span className="icon-text xsTitle date-width flex-space">
                            {el?.title}
                          </span>

                          <span className="xsTitle c-orange ">
                            {amtStr(el?.title2)}
                          </span>
                        </>
                      ) : (
                        <span className="icon-text xsTitle date-width flex-space">
                          {el?.title}
                        </span>
                      )
                    }
                    secondary={
                      <>
                        {el.label1 ? (
                          <span className="desc">
                            <span className="desc">{el.label1}</span>
                            &nbsp;
                            <span className="desc c-orange">{el.data1}</span>
                          </span>
                        ) : (
                          <>
                            <span className="desc">
                              <span className="text c-orange">
                                {`${el?.draft ?? 0}`}&nbsp;
                              </span>
                              <span className="text">
                                {`Draft`}&nbsp;&nbsp;{' '}
                              </span>
                              <span className="text c-orange">
                                {`${el?.submitted ?? 0}`}&nbsp;
                              </span>
                              <span className="text">{`Submitted`}</span>
                            </span>
                          </>
                        )}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <KeyboardArrowRight
                        onClick={() =>
                          !el?.disabled
                            ? history.push({
                                pathname: el.path,
                                //state: totalCreditorDocAmt,
                              })
                            : null
                        }
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          })}
        </List>
      </ContentWrapper>
      <SwitchDialog
        open={change}
        onClose={() => setSwitch(false)}
        data={getAssignedCompanyByUser}
        name={'Company'}
        nameID={'CompanyID'}
        currID={homeCompanyID}
        setCurrID={setHomeCompanyID}
        path={`/account-payable`}
      />

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />
      {/* FOOTER */}
    </>
  )
}
