import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar'
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { InactiveDialog } from 'components/Dialog/InactiveDialog'
import { CopyrightFooter } from 'components/Footer/Copyright'
import SnackBarContext from 'containers/App/Store/SnackBarContext'
import {
  CreditorStatus,
  CreditorType,
  GetCreditorAccountDocument,
  useGetCreditorAccountLazyQuery,
  useGetCreditorAccountListingLazyQuery,
  useGetLocalBankQuery,
  useGetTaxSchemeQuery,
  useUpdateCreditorAccountMutation,
} from 'generated/graphql'
import { useMenuOption } from 'helpers/CustomHooks/useMenuOption'
import { useFuseSearch } from 'helpers/Hooks/useSearch'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ExcelInput } from '@ifca-root/react-component/src/components/Input/ExcelUploadInput'
import { useExcelUploadVersion2 } from '@ifca-root/react-component/src/utils/hooks/excelUploadVersion2'
import { CreditorProfileTemplateForm } from './CreditorProfileTemplateForm'
import IconText from '@ifca-root/react-component/src/components/Typography/IconText'
import DownloadIcon from '@material-ui/icons/GetApp'
import ExcelIcon from '@account-root/account-react/src/assets/icons/excel.svg'

export const CreditorProfileListing = (props: any) => {
  const { creditorType }: any = useParams()
  let history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const { filteredList, handleSearch, setOriginalListing } = useFuseSearch()
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption()
  const [inactiveCreditor, setInactiveCreditor] = useState(false)
  const [activeCreditor, setActiveCreditor] = useState(false)
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any
  )
  const getSearch = JSON.parse(localStorage.getItem('searchFilter'))

  const [TotalLength, setTotalLength] = useState(0)
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')

  let typeOfCreditor = type => {
    let temp
    switch (type) {
      case 'supplier':
        temp = CreditorType.Supplier
        break
      case 'contractor':
        temp = CreditorType.Contractor
        break
      case 'consultant':
        temp = CreditorType.Consultant
        break
      case 'agent':
        temp = CreditorType.Agent
        break
    }
    return temp
  }

  const [
    loadTaxData,
    {
      called: CreditorAccountCalled,
      loading: CreditorAccountLoading,
      data: { getCreditorAccount } = { getCreditorAccount: [] },
    },
  ] = useGetCreditorAccountListingLazyQuery({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    loadTaxData()
  }, [creditorType])

  const [
    updateCreditorAccount,
    { loading: UpdateLoading, error: updateError },
  ] = useUpdateCreditorAccountMutation({
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(errorMessage)
    },
    //fetchPolicy: 'network-only',
    onCompleted: data => {
      setOpenSnackBar(true)
      setSnackBarMsg(SystemMsgs.updateRecord())
      setTimeout(() => {
        history.push({
          pathname: `/account-payable/general-setting/creditor-profile/${creditorType}`,
          state: { success: true, msgMode: 'update' },
        })
      }, 500)
    },
  })

  useEffect(() => {
    if (getCreditorAccount && getCreditorAccount?.length > 0) {
      let allData = []
      allData.push(
        ...getCreditorAccount?.filter(
          x =>
            x?.CreditorTypeEnum === typeOfCreditor(creditorType) &&
            x?.CreditorStatus === 'ACTIVE'
        )
      )
      allData.push(
        ...getCreditorAccount?.filter(
          x =>
            x?.CreditorTypeEnum === typeOfCreditor(creditorType) &&
            x?.CreditorStatus === 'INACTIVE'
        )
      )
      setOriginalListing(allData)
    }
  }, [getCreditorAccount, creditorType])

  const enumListing = [
    { name: 'Supplier', ID: 'supplier' },
    { name: 'Contractor', ID: 'contractor' },
    { name: 'Consultant', ID: 'consultant' },
    { name: 'Agent', ID: 'agent' },
  ]

  const handleAccTypeChange = e => {
    //setAccountType(e)
    localStorage.setItem('latestCreditorType', e)
    history.push(`/account-payable/general-setting/creditor-profile/${e}`)
  }

  const creditorObj = {
    CreditorAccountID: menu?.obj?.CreditorAccountID,
    CreditorTypeID: menu?.obj?.CreditorTypeID,
    CompanyRegNo: menu?.obj?.CompanyRegNo,
    ContactNo: menu?.obj?.ContactNo,
    CompanyName: menu?.obj?.CompanyName,
    CreditTerm: menu?.obj?.CreditTerm,
    CreditorStatus: CreditorStatus.Inactive,
    CreditorTypeEnum: menu?.obj?.CreditorTypeEnum,
  }

  const onInactive = data => {
    if (inactiveCreditor === true) {
      updateCreditorAccount({
        variables: {
          input: {
            CreditorAccountID: data?.CreditorAccountID,
            CreditorTypeID: data?.CreditorTypeID,
            CompanyRegNo: data?.CompanyRegNo,
            ContactNo: data?.ContactNo,
            CompanyName: data?.CompanyName,
            CreditTerm: data?.CreditTerm,
            CreditorStatus: CreditorStatus.Inactive,
            CreditorTypeEnum: data?.CreditorTypeEnum,
          },
        },
        refetchQueries: [
          {
            query: GetCreditorAccountDocument,
          },
        ],
      })
      setInactiveCreditor(false)
    } else if (activeCreditor === true) {
      updateCreditorAccount({
        variables: {
          input: {
            CreditorAccountID: data?.CreditorAccountID,
            CreditorTypeID: data?.CreditorTypeID,
            CompanyRegNo: data?.CompanyRegNo,
            ContactNo: data?.ContactNo,
            CompanyName: data?.CompanyName,
            CreditTerm: data?.CreditTerm,
            CreditorStatus: CreditorStatus.Active,
            CreditorTypeEnum: data?.CreditorTypeEnum,
          },
        },
        refetchQueries: [
          {
            query: GetCreditorAccountDocument,
          },
        ],
      })
      setActiveCreditor(false)
    }
  }

  const {
    openFileUpload,
    setOpenFileUpload,
    fileTitle,
    setFileTitle,
    openSnackBar: opensnackbar,
    setOpenSnackBar: setOpensnackbar,
    snackBarMessage,
    setSnackBarMessage,
    currentData,
    setCurrentData,
    onDownloadTemplate,
    onSelectFile,
    duplicateItems,
    errorDialog: duplicateErrorDia,
    setErrorDialog,
    resetFileUpload,
  } = useExcelUploadVersion2()

  const {
    loading: taxLoading,
    error: taxError,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: {
      AccTaxClass: 'INPUT',
      orderByAsc: 'Code',
    },
    onCompleted: () => {},
  })

  const {
    loading: localBankLoading,
    error: localBankError,
    data: { getLocalBank } = { getLocalBank: [] },
  } = useGetLocalBankQuery({
    variables: { orderByAsc: 'name' },
  })

  return (
    <>
      {UpdateLoading && <Loading />}
      {localBankLoading && <Loading />}
      {taxLoading && <Loading />}
      {CreditorAccountLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/account-payable/general-setting/`)} //<- back button action
        smTitle={'Accounts Payable'}
        title={user?.accountName}
        routeSegments={[
          { name: 'General Setting' },
          { name: 'Creditor Profile', current: true },
        ]}
      />

      <div className="search-filter with-dropdown-filter ">
        <SearchBar
          borderRadius="4px"
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['CompanyName'])
          }}
        />

        <DropdownBar
          options={enumListing}
          setOptionID={handleAccTypeChange}
          borderRadius="4px"
          defaultOption={enumListing.findIndex(e => e?.ID === creditorType)}
        />
      </div>
      {!CreditorAccountLoading && (
        <ContentWrapper float>
          <div className="message-alert text-noflow">
            <div className="flex-space">
              <IconText
                icon={
                  <img
                    src={ExcelIcon}
                    style={{ width: '19px', paddingRight: '5px' }}
                  />
                }
                font="mdDesc"
                children={
                  <>
                    <span style={{ fontWeight: 'bold' }}>Upload </span>{' '}
                    <span>Excel Template</span>
                  </>
                }
              ></IconText>
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  minWidth: '0px',
                  width: '32px',
                  height: '24px',
                  margin: '-2px 0px -2px 0px',
                }}
                onClick={() => {
                  setOpenFileUpload(true)
                }}
              >
                <DownloadIcon
                  style={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              </Button>
            </div>
          </div>
          <List className="core-list">
            {filteredList === undefined || filteredList?.length === 0 ? (
              <EmptyList
                title="No Record found"
                subtitle="Add a new record now."
              />
            ) : (
              filteredList
                .sort((a, b) => {
                  return a.CompanyName.localeCompare(b.CompanyName)
                })
                ?.map((el, index) => {
                  return (
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            <span
                              className="xsTitle flex-space"
                              style={{
                                color:
                                  el?.CreditorStatus === 'INACTIVE'
                                    ? '#BDBDBD'
                                    : null,
                              }}
                            >
                              {el?.CompanyName}
                            </span>
                          </>
                        }
                        secondary={
                          <span
                            className="desc"
                            style={{
                              color:
                                el?.CreditorStatus === 'INACTIVE'
                                  ? '#BDBDBD'
                                  : null,
                            }}
                          >
                            <span className="fw-medium">
                              {el?.CompanyRegNo}
                            </span>
                          </span>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e => {
                            handleClick(e, el?.ID, index, el)
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
                })
            )}
          </List>
          <FloatButton
            onClick={() =>
              history.push(
                `/account-payable/general-setting/creditor-profile/${creditorType}/add`
              )
            }
          />
        </ContentWrapper>
      )}

      <ExcelInput
        showFooter={false}
        showDupErrors={true}
        duplicateItems={duplicateItems}
        errorDia={duplicateErrorDia}
        setErrorDia={setErrorDialog}
        openUpload={openFileUpload}
        setOpenUpload={setOpenFileUpload}
        upload={fileTitle}
        template={currentData}
        setTemplate={setCurrentData}
        setUpload={setFileTitle}
        resetFileUpload={resetFileUpload}
        onUploadTemplate={() => {
          history.push({
            pathname: `/account-payable/general-setting/creditor-profile/import/excel-validation`,
            state: {
              excelData: currentData?.map(x => {
                return {
                  CreditorCategory:
                    x?.CreditorCategory === null
                      ? null
                      : `${x?.CreditorCategory}`,
                  CompanyRegNo:
                    x?.CompanyRegNo === null ? null : `${x?.CompanyRegNo}`,
                  TaxIdentificationNo:
                    x?.TaxIdentificationNo === null
                      ? null
                      : `${x?.TaxIdentificationNo}`,
                  CompanyName:
                    x?.CompanyName === null ? null : `${x?.CompanyName}`,
                  CreditorType:
                    x?.CreditorType === null ? null : `${x?.CreditorType}`,
                  ContactNo: x?.ContactNo === null ? null : `${x?.ContactNo}`,
                  Email: x?.Email === null ? null : x?.Email.text,
                  CreditTerm:
                    x?.CreditTerm === null ? null : `${x?.CreditTerm}`,
                  Tax: x?.Tax === null ? null : `${x?.Tax}`,
                  Region: x?.Region === null ? null : `${x?.Region}`,
                  GSTRegNo: x?.GSTRegNo === null ? null : `${x?.GSTRegNo}`,
                  Remark: x?.Remark === null ? null : `${x?.Remark}`,
                  BankAccountNo:
                    x?.BankAccountNo === null ? null : `${x?.BankAccountNo}`,
                  BankName: x?.BankName === null ? null : `${x?.BankName}`,
                  SWIFTCode: x?.SWIFTCode === null ? null : `${x?.SWIFTCode}`,
                  PayeeName: x?.PayeeName === null ? null : `${x?.PayeeName}`,
                  Address: x?.Address === null ? null : `${x?.Address}`,
                  Country: x?.Country === null ? null : `${x?.Country}`,
                  State: x?.State === null ? null : `${x?.State}`,
                  City: x?.City === null ? null : `${x?.City}`,
                  PostCode: x?.PostCode === null ? null : `${x?.PostCode}`,
                  RowNo: x?.rowNo,
                }
              }),
              fileTitle: fileTitle,
            },
          })
          setOpenFileUpload(false)
        }}
        onSelectFile={(e: any) => {
          onSelectFile({ event: e })
        }}
        downloadButton={true}
        onDownloadTemplate={() => {
          onDownloadTemplate({
            name: `Creditor Profile Spreadsheet`,
            workSheetName: `Creditor Profile List`,
            headerSetup: CreditorProfileTemplateForm,
            isHeaderLocked: true,
            defaultHeaderFillStyle: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            },
            defaultHeaderBorderStyle: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            },
            extraListData: [
              {
                data: ['Supplier', 'Contractor', 'Consultant', 'Agent'],
                worksheetName: 'CreditorCategory',
                dataType: 'CreditorCategory',
              },
              {
                data: getTaxScheme?.map(x => x?.Code),
                worksheetName: 'Tax',
                dataType: 'Tax',
              },
              {
                data: getLocalBank?.map(x => x?.name),
                worksheetName: 'BankName',
                dataType: 'BankName',
              },
            ],
          })
        }}
        snackBarProps={{
          openSnackBar: opensnackbar,
          snackBarMessage: snackBarMessage,
          setOpenSnackBar: setOpensnackbar,
          onClickButton: () => {
            setOpensnackbar(false)
          },
        }}
      />

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <>
          {menu?.obj?.CreditorStatus === 'ACTIVE' ? (
            <>
              <MenuItem
                onClick={() => {
                  history.push({
                    pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${menu?.obj?.CreditorAccountID}/assign`,
                  })
                }}
              >
                <span className="">Assign</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push({
                    pathname: `/account-payable/general-setting/creditor-profile/${creditorType}/${menu?.obj?.CreditorAccountID}/detail`,
                  })
                }}
              >
                <span className="">View</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setInactiveCreditor(true)
                }}
              >
                <span className="">Inactive</span>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  setActiveCreditor(true)
                }}
              >
                <span className="">Active</span>
              </MenuItem>
            </>
          )}
        </>
      </Menu>
      <InactiveDialog
        ID={creditorObj}
        openInactiveDialog={inactiveCreditor}
        setOpenInactiveDialog={setInactiveCreditor}
        title={menu?.obj?.CompanyName}
        menu={menu?.obj}
        handleInactive={onInactive}
        action={'Inactive'}
        handleAction={'inactive'}
      />

      <InactiveDialog
        ID={creditorObj}
        openInactiveDialog={activeCreditor}
        setOpenInactiveDialog={setActiveCreditor}
        title={menu?.obj?.CompanyName}
        menu={menu?.obj}
        handleInactive={onInactive}
        action={'Active'}
        handleAction={'activate'}
      />
    </>
  )
}
