import DateFnsUtils from '@date-io/date-fns'
import { yupResolver } from '@hookform/resolvers'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTextField'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import Loading from '@ifca-root/react-component/src/components/Loading/Loading'
import { ListSubheader, MenuItem, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { BigNumber } from 'bignumber.js'
import { ErrorDialog } from 'components/Dialog/ErrorDialog'
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog'
import { AccCodeDropdownFullWidth } from 'components/Dropdown/AccCodeDropdown'
import { AccountFooter } from 'components/Footer/AccountFooter'
import {
  getTaxRate,
  getTotalAmt,
} from 'containers/CashBookModule/Receipt/receipt'
import {
  RecordStatus,
  useGetCompanyNameQuery,
  useGetDepartmentListingByCoaLazyQuery,
  useGetMasterCoaForFormQuery,
  useGetTaxSchemeQuery,
} from 'generated/graphql'
import { handleExitConfirmation } from 'helpers/Form/ExitConfirmation'
import { formatDashDate } from 'helpers/StringNumberFunction/FormatDate'
import { amtNumStr, amtStr } from 'helpers/StringNumberFunction/NumFormatters'
import { CommonYupValidation } from 'helpers/YupSchema/yup'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { uuid } from 'uuidv4'
import * as yup from 'yup'
import '../Payment.scss'
import { SystemMsgs } from 'helpers/Messages/SystemMsg'

interface PaymentItemProps {
  DocDate: string
  MasterCOAID: string
  Amount: number
  TaxSchemeID: string
  TaxRate: number
  TaxAmt: number
  DocAmt: number
  CostCentreID: string
  Remark: string
  DocNo: string
}

export const PaymentItemForm = (props: any) => {
  const {
    CompanyID,
    BankAccountID,
    PaymentID,
    PaymentItemID,
    formMode,
    detailMode,
  }: any = useParams()

  let form, mode
  switch (detailMode) {
    case 'add':
      form = 'New'
      mode = 'add'
      break
    case 'edit':
      form = 'Edit'
      mode = 'edit'
  }

  let history = useHistory()
  let location = useLocation()
  const editData = location?.state as any
  const itemEditData: any = JSON.parse(
    localStorage.getItem('paymentItem')
  )?.filter(item => item?.PaymentItemID === PaymentItemID)[0]

  /* -------------------------------------------- */
  /*                    STATE                     */
  /* -------------------------------------------- */
  const [cost, setCost] = useState(
    amtNumStr(PaymentItemID && itemEditData?.Amount) ?? 0
  )
  const [TaxRate, setTaxRate] = useState(0)
  const [selectedTax, setSelectedTax] = useState(null)
  const [openExitConf, setOpenExitConf] = useState(null)
  const [record, setRecord] = useState(false)
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const pmtdetail = JSON.parse(localStorage.getItem('paymentItem'))
  const [errorDia, setErrorDia] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [mountDefVal, setMountDefVal] = useState(!!itemEditData)
  const [voiceRemark, setVoiceRemark] = useState('')

  /* -------------------------------------------- */
  /*                    USE FORM                     */
  /* -------------------------------------------- */
  const PaymentItemFormSchema = yup.object().shape({
    MasterCOAID: CommonYupValidation.requireField('Account Code is required'),
    CostCentreID: CommonYupValidation.requireField(SystemMsgs.costCentre()),
    TaxSchemeID: CommonYupValidation.requireField('Tax Code is required'),
    Remark: CommonYupValidation.requireField('Remark is required'),
    Amount: CommonYupValidation.requireField('Amount is required'),
  })

  const {
    handleSubmit,
    register,
    setValue,
    control,
    errors,
    reset,
    setError,
    getValues,
    formState,
    watch,
    clearErrors,
  } = useForm<PaymentItemProps>({
    defaultValues: {
      DocNo: PaymentItemID && itemEditData ? itemEditData?.DocNo : '',
      DocDate: PaymentItemID && itemEditData ? itemEditData?.DocDate : null,
      MasterCOAID:
        PaymentItemID && itemEditData ? itemEditData?.MasterCOAID : '',
      Amount: PaymentItemID && itemEditData ? itemEditData?.Amount : 0,
      TaxSchemeID:
        PaymentItemID && itemEditData ? itemEditData?.TaxSchemeID : '',
      TaxRate: PaymentItemID && itemEditData ? itemEditData?.TaxRate : 0,
      TaxAmt: PaymentItemID && itemEditData ? itemEditData?.TaxAmt : 0,
      DocAmt: PaymentItemID && itemEditData ? itemEditData?.DocAmt : 0,
      // CostCentreID:
      //   PaymentItemID && itemEditData ? itemEditData?.CostCentreID : '',
      Remark: PaymentItemID && itemEditData ? itemEditData?.Remark : '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(PaymentItemFormSchema),
  })

  const {
    loading: CompanyLoading,
    data: { getCompany: curCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
  })

  // Tax
  const {
    loading: TaxLoading,
    data: { getTaxScheme } = { getTaxScheme: [] },
  } = useGetTaxSchemeQuery({
    variables: { RecordStatus: RecordStatus.Active, AccTaxClass: 'INPUT' },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
    onCompleted: () => {
      if (detailMode === 'edit') {
        setSelectedTax(
          taxScheme?.find(x => x?.TaxSchemeID === itemEditData?.TaxSchemeID)
        )
        setTaxRate(
          taxScheme
            .filter(x => x?.TaxSchemeID === itemEditData?.TaxSchemeID)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        )
      }
    },
  })

  const {
    loading: MasterCOALoading,
    data: { getMasterCOAForForm } = { getMasterCOAForForm: [] },
  } = useGetMasterCoaForFormQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
    onError: ({ message }) => {
      let error = message?.substring(15)
      setErrMsg(error)
      setErrDialog(true)
    },
    onCompleted: ({ getMasterCOAForForm }) => {
      if (itemEditData?.MasterCOAID) {
        let accountType = getMasterCOAForForm?.find(
          x => x?.MasterCOAID === itemEditData?.MasterCOAID
        )?.AccountType
        fetchDepartmentListingForForm({
          variables: {
            CompanyID,
            MasterCOAID: itemEditData?.MasterCOAID,
            AccountType: accountType,
          },
        })
      }
    },
  })

  const [
    fetchDepartmentListingForForm,
    {
      loading: HandlerLoading,
      data: { getDepartmentListingByCOA } = { getDepartmentListingByCOA: [] },
    },
  ] = useGetDepartmentListingByCoaLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getDepartmentListingByCOA }) => {
      if (
        getDepartmentListingByCOA?.length === 1 &&
        !!getDepartmentListingByCOA[0]?.IsDefault
      ) {
        setValue('CostCentreID', getDepartmentListingByCOA[0]?.CostCentreID)
      }
      if (!!itemEditData?.CostCentreID && mountDefVal === true) {
        setValue('CostCentreID', itemEditData?.CostCentreID)
      }
    },
    onError: error => {
      let errorMessage = error.message.substring(15)
      console.log('ERROR', error)
      setErrorDia(true)
      setErrMsg(`${errorMessage}"`)
    },
  })

  // Change Tax descrition into code number
  const [totalAmt, setTotalAmt] = useState(0.0)
  const [amount, setAmount] = useState(0.0)
  const [taxAmt, setTaxAmt] = useState(
    !!itemEditData && PaymentItemID ? itemEditData?.TaxAmt : 0
  )
  const taxScheme: any = getTaxScheme

  // change tax
  const handleTaxChange = (event, taxEffectiveDate) => {
    setSelectedTax(taxScheme.find(x => x?.TaxSchemeID === event?.target?.value))
    // setTaxRate(
    //   taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
    //     .LatestTax?.Rate
    // )

    setTaxRate(
      taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
        .LatestTax === null
        ? taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
        : taxScheme
            .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
            ?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            )
            .reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
    )

    setTaxAmt(
      new BigNumber(
        taxScheme.filter(x => x?.TaxSchemeID === event?.target?.value)[0]
          .LatestTax === null
          ? taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective.reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
          : taxScheme
              .filter(x => x?.TaxSchemeID === event?.target?.value)[0]
              ?.TaxEffective?.filter(
                x => new Date(x?.Date) <= new Date(taxEffectiveDate)
              )
              .reduce((a, b) => {
                return new Date(a.Date) > new Date(b.Date) ? a : b
              })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(cost)
        .toNumber()
    )
  }

  const handleAmtChange = event => {
    setCost(event.value)

    setTaxAmt(
      new BigNumber(
        selectedTax?.LatestTax === null
          ? selectedTax?.TaxEffective.reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
          : selectedTax?.TaxEffective?.filter(
              x => new Date(x?.Date) <= new Date(taxEffectiveDate)
            ).reduce((a, b) => {
              return new Date(a.Date) > new Date(b.Date) ? a : b
            })?.Rate
      )
        .dividedBy(100)
        .multipliedBy(event.value)
        .toNumber()
    )
  }

  const handleTaxAmtChange = event => {
    setTaxAmt(event.value)
  }

  const calculateTotalAmt = () => {
    setTotalAmt(amount + amount * (TaxRate / 100))
  }

  /* -------------------------------------------- */
  /*                   USE EFFECT                 */
  /* -------------------------------------------- */

  // Autoset tax after select tax amount
  useEffect(() => {
    if (!!selectedTax) {
      setValue('TaxSchemeID', selectedTax?.TaxSchemeID)
      clearErrors('TaxSchemeID')
    }
  }, [selectedTax])

  // change tax amount(controller) *taxAmtChangeableNeeded only
  useEffect(() => {
    if (!isNaN(taxAmt)) {
      setValue('TaxAmt', taxAmt)
      clearErrors('TaxAmt')
    }
  }, [taxAmt])

  useEffect(() => {
    calculateTotalAmt()
  }, [amount, TaxRate])

  // To get list of Tax Code that has been effective //
  const taxEffectiveDate: any = new Date(
    new Date(JSON.parse(localStorage.getItem('payment'))?.DocDate).getTime() +
      24 * 60 * 60 * 1000
  )
    .toISOString()
    .slice(0, 10)

  const taxSchemeInput: any = getTaxScheme?.filter(
    el =>
      el?.AccTaxClass === 'INPUT' &&
      el?.TaxEffective.map(y => y?.Date) <= taxEffectiveDate
  )

  //--- Submit Payment Item to LocalStorage
  const onSubmit = data => {
    const prvdetail =
      detailMode === 'add'
        ? JSON.parse(localStorage.getItem('paymentItem')) || []
        : JSON.parse(localStorage.getItem('paymentItem')).filter(
            x => x?.PaymentItemID !== PaymentItemID
          )

    localStorage.setItem(
      'paymentItem',
      JSON.stringify([
        ...(prvdetail?.length === null || 0 ? null : prvdetail),

        {
          Sequence: itemEditData?.Sequence ?? prvdetail?.length + 1,
          PaymentItemID: detailMode === 'add' ? uuid() : PaymentItemID,
          DocNo:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? data?.DocNo
              : itemEditData?.DocNo,
          DocDate:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? formatDashDate(new Date(data?.DocDate)?.toISOString())
              : itemEditData?.DocDate,
          MasterCOAID:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? data?.MasterCOAID
              : itemEditData?.MasterCOAID,
          ExpenseCode:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === data?.MasterCOAID
                )[0]?.Code
              : getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.Code,
          ExpenseName:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === data?.MasterCOAID
                )[0]?.Name
              : getMasterCOAForForm.filter(
                  coa => coa?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.Name,
          Amount:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? parseFloat(amtNumStr(data?.Amount))
              : itemEditData?.Amount,
          TaxSchemeID:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? data?.TaxSchemeID
              : itemEditData?.TaxSchemeID,
          TaxCode:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getTaxScheme.filter(
                  taxcode => taxcode?.TaxSchemeID === data?.TaxSchemeID
                )[0]?.Code
              : getTaxScheme.filter(
                  taxcode => taxcode?.TaxSchemeID === itemEditData?.TaxSchemeID
                )[0]?.Code,
          TaxRate:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getTaxRate(TaxRate)
              : getTaxScheme?.find(
                  x => x?.TaxSchemeID === itemEditData?.TaxSchemeID
                )?.LatestTax?.Rate,
          TaxAmt:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? parseFloat(data?.TaxAmt)
              : itemEditData?.TaxAmt,
          DocAmt:
            formMode === 'add' || formMode === 'edit' || formMode === 'resubmit'
              ? getTotalAmt(true, cost, taxAmt, TaxRate)
              : itemEditData?.DocAmt,
          CostCentreID: data?.CostCentreID,
          CostCentreCode: getDepartmentListingByCOA.filter(
            ccc => ccc?.CostCentreID === data?.CostCentreID
          )[0]?.Code,
          Remark: data?.Remark,
        },
      ])
    )

    if (
      (formMode === 'add' && detailMode === 'add') ||
      (formMode === 'add' && detailMode === 'edit')
    ) {
      history.push({
        pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${formMode}`,
        state: { ...editData },
      })
    } else if (
      (formMode === 'edit' && detailMode === 'edit') ||
      (formMode === 'edit' && detailMode === 'add') ||
      formMode === 'approve-reject'
    )
      history.push({
        pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/${formMode}`,
        state: { ...editData, detailMode: 'add' },
      })
  }

  /* -------------------------------------------- */
  /*               EXIT CONFIRMATION              */
  /* -------------------------------------------- */
  const hasChanges = () =>
    handleExitConfirmation({
      watch: watch,
      editData: itemEditData,
      formMode: detailMode,
    })

  return (
    <>
      {TaxLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {HandlerLoading && <Loading />}
      {MasterCOALoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
          // }
        }}
        smTitle={'Cash Book'}
        title={curCompany[0]?.Name}
        routeSegments={[
          { name: 'Cash Book' },
          { name: 'Cash Book' },
          { name: 'Payment' },
          { name: 'Payment', current: true },
        ]}
        rightRouteSegments={[
          { name: detailMode === 'add' ? 'New' : 'Edit', current: true },
        ]}
      />
      <ContentWrapper float>
        <CardContents section={{ header: { title: 'Payment Detail' } }}>
          <Controller
            as={TextField}
            name="DocNo"
            label="Document No."
            margin="dense"
            required
            id="standard-basic"
            InputLabelProps={
              watch('DocNo') !== ''
                ? {
                    shrink: true,
                  }
                : null
            }
            autoComplete="off"
            control={control}
            className="left"
            ref={register}
            helperText={errors?.DocNo?.message}
            error={errors?.DocNo ? true : false}
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
              as={KeyboardDatePicker}
              name="DocDate"
              required
              label="Document Date"
              control={control}
              format="dd/MM/yyyy"
              margin="dense"
              allowKeyboardControl
              onChange={(date: Date | null) => {}}
              ref={register}
              onAccept={date => {}}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{ shrink: true }}
              showTodayButton
              className="right"
              value={watch('DocDate')}
              helperText={errors?.DocDate?.message}
              error={errors?.DocDate ? true : false}
            />
          </MuiPickersUtilsProvider>
          {!MasterCOALoading && (
            <Controller
              render={({ value, onChange }) => {
                const defVal = getMasterCOAForForm?.filter(
                  x => x?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]

                return (
                  <Autocomplete
                    options={getMasterCOAForForm || []}
                    getOptionLabel={option =>
                      `${option?.Code} | ${option?.Name}`
                    }
                    fullWidth
                    onChange={(value, newValue: any) => {
                      onChange(newValue?.MasterCOAID)
                      setMountDefVal(false)
                      setValue('CostCentreID', null)
                      fetchDepartmentListingForForm({
                        variables: {
                          CompanyID,
                          MasterCOAID: newValue?.MasterCOAID ?? '',
                          AccountType: newValue?.AccountType ?? '',
                        },
                      })
                    }}
                    defaultValue={defVal}
                    disabled={mode === 'approve-reject'}
                    PopperComponent={AccCodeDropdownFullWidth}
                    renderOption={(props, option) => {
                      return (
                        <div>
                          <div>
                            <span className="xsTitle">{props?.Code}</span>
                          </div>
                          <div className="desc">{props?.Name}</div>
                        </div>
                      )
                    }}
                    renderInput={(params: any) => {
                      return (
                        <div>
                          <TextField
                            {...params}
                            helperText={errors?.MasterCOAID?.message}
                            error={errors?.MasterCOAID ? true : false}
                            label="Account Code *"
                            style={{ width: '100%' }}
                            disabled={mode === 'approve-reject'}
                            margin="normal"
                            variant="standard"
                          />
                        </div>
                      )
                    }}
                  />
                )
              }}
              label="Account Code "
              name="MasterCOAID"
              autoComplete="off"
              control={control}
              multiline={true}
              fullWidth
              margin="normal"
              ref={register}
              helperText={errors?.MasterCOAID?.message}
              error={errors?.MasterCOAID ? true : false}
              defaultValue={
                getMasterCOAForForm?.filter(
                  x => x?.MasterCOAID === itemEditData?.MasterCOAID
                )[0]?.MasterCOAID
              }
              required
              disabled={formMode === 'approve-reject' ? true : false}
            />
          )}
          <Controller
            render={({ value, onChange }) => {
              const defVal =
                getDepartmentListingByCOA?.find(
                  x => x?.CostCentreID === watch('CostCentreID')
                ) ?? null
              return (
                <Autocomplete
                  options={getDepartmentListingByCOA || []}
                  getOptionLabel={option =>
                    option ? `${option?.Code} | ${option?.Name}` : ''
                  }
                  fullWidth
                  onChange={(value, newValue: any) => {
                    onChange(newValue?.CostCentreID)
                  }}
                  disabled={formMode === 'approve-reject'}
                  disableClearable={
                    !!(
                      getDepartmentListingByCOA?.length === 1 &&
                      getDepartmentListingByCOA[0]?.IsDefault === true
                    )
                  }
                  renderOption={(props, option) => {
                    return (
                      <div>
                        <div>
                          <span className="xsTitle">{props?.Code}</span>
                        </div>
                        <div className="desc">{props?.Name}</div>
                      </div>
                    )
                  }}
                  value={
                    !!getDepartmentListingByCOA[0]?.IsDefault
                      ? getDepartmentListingByCOA[0]
                      : defVal
                  }
                  renderInput={(params: any) => {
                    return (
                      <div>
                        <TextField
                          {...params}
                          helperText={
                            errors?.CostCentreID ||
                            watch('CostCentreID') === null ||
                            watch('CostCentreID') === undefined
                              ? 'Department is required'
                              : null
                          }
                          error={errors?.CostCentreID ? true : false}
                          label="Department"
                          style={{ width: '100%' }}
                          margin="dense"
                          disabled={
                            !!(
                              getDepartmentListingByCOA?.length === 1 &&
                              getDepartmentListingByCOA[0]?.IsDefault === true
                            )
                          }
                          required
                        />
                      </div>
                    )
                  }}
                />
              )
            }}
            label="Department"
            name="CostCentreID"
            autoComplete="off"
            control={control}
            multiline={true}
            fullWidth
            margin="dense"
            ref={register}
            helperText={
              errors?.CostCentreID ||
              watch('CostCentreID') === null ||
              watch('CostCentreID') === undefined
                ? 'Department is required'
                : null
            }
            error={errors?.CostCentreID ? true : false}
            disabled={
              !!(
                getDepartmentListingByCOA?.length === 1 &&
                getDepartmentListingByCOA?.find(
                  x => x?.CostCentreID === watch('CostCentreID')
                )?.IsDefault === true
              )
            }
            required
          />
          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            id="standard-basic"
            name="Amount"
            label="Amount"
            value={cost}
            autoComplete="off"
            control={control}
            onValueChange={e => {
              handleAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            margin="dense"
            required
            helperText={errors?.Amount?.message}
            error={errors?.Amount ? true : false}
            ref={register}
            defaultValue={PaymentItemID ? itemEditData?.Amount : 0}
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <Controller
            as={
              <TextField
                className="left"
                margin="dense"
                label="Tax Code"
                required
                SelectProps={{
                  renderValue: () => `${selectedTax?.Code}`,
                  onChange: event => {
                    handleTaxChange(event, taxEffectiveDate)
                    setValue('TaxSchemeID', event?.target?.value.toString())
                    clearErrors('TaxSchemeID')
                  },
                }}
                select
              >
                <ListSubheader>Input</ListSubheader>
                {taxSchemeInput?.map((tax, index) => (
                  <MenuItem
                    id="tax-select"
                    key={index}
                    value={tax?.TaxSchemeID}
                  >
                    <span className="text-noflow">{`${tax?.Code}`}</span>
                  </MenuItem>
                ))}
              </TextField>
            }
            select
            name="TaxSchemeID"
            autoComplete="off"
            control={control}
            fullWidth
            ref={register}
            helperText={errors?.TaxSchemeID?.message}
            error={errors?.TaxSchemeID ? true : false}
            disabled={formMode === 'approve-reject' ? true : false}
          />

          <TextField
            name="TaxRate"
            label="Tax Rate %"
            value={amtStr(getTaxRate(TaxRate))}
            disabled
            className="right"
            margin="normal"
            ref={register}
            style={{ marginTop: '5px' }}
          />

          <Controller
            as={<NumberFormat allowNegative={false} />}
            thousandSeparator
            customInput={TextField}
            ref={register}
            control={control}
            margin="normal"
            name="TaxAmt"
            label="Tax Amount"
            disabled={formMode === 'approve-reject' ? true : false}
            onValueChange={e => {
              handleTaxAmtChange(e)
            }}
            decimalScale={2}
            fixedDecimalScale
            className="left"
            required
            defaultValue={PaymentItemID ? itemEditData?.TaxAmt : 0}
          />

          <TextField
            name="DocAmt"
            variant="outlined"
            label="Document Amount"
            value={amtStr(getTotalAmt(true, cost, taxAmt, TaxRate))}
            InputProps={{
              style: {
                color: '#96938e',
                backgroundColor: 'white',
              },
            }}
            className="outlined-disabled right"
            aria-disabled
            margin="normal"
            ref={register}
            style={{ marginTop: '25px', color: 'white' }}
            disabled
          />

          <VoiceTextField
            mounted={true}
            label="Remark"
            name="Remark"
            required
            value={voiceRemark}
            setValue={setValue}
            record={record}
            setRecord={setRecord}
            control={control}
            controllerProps={{
              error: errors?.Remark ? true : false,
              helperText: errors?.Remark?.message,
              ref: register,
              autoComplete: 'off',
            }}
          />
        </CardContents>
      </ContentWrapper>

      <ErrorDialog
        errorDia={errorDia}
        setErrorDia={setErrorDia}
        errorMsg={errMsg}
        errorHeaderMsg={'Error!'}
      />

      <AccountFooter
        options={[
          {
            name: 'Save',
            color: 'primary',
            onClick: () => {
              handleSubmit(data => onSubmit(data))()
            },
            props: { type: 'submit' },
          },
        ]}
      />

      <ExitConfirmationDialog
        openExitConf={openExitConf}
        setOpenExitConf={setOpenExitConf}
        onConfirm={() => {
          if (formMode === 'add')
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${formMode}`,
              state: { ...editData },
            })
          else {
            history.push({
              pathname: `/cash-book/${CompanyID}/submenu/${BankAccountID}/payment/${PaymentID}/${formMode}`,
              state: { ...editData, detailMode: 'add' },
            })
          }
        }}
      />
    </>
  )
}
function setErrDialog(arg0: boolean) {
  throw new Error('Function not implemented.')
}
